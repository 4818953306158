/* eslint-disable callback-return */
import { Middleware, Dispatch, Action, AnyAction } from "redux";
import Parse from "parse";
import { REHYDRATE } from "redux-persist";
import { hydrateUser } from "../model/user";
import { ReduxStore } from "../types";

export const onRehydrationMiddleware: Middleware<ReduxStore>
	= store => (next: Dispatch<AnyAction>) => (action: Action) => {
		next(action);
		if (action.type === REHYDRATE) {
			// If Parse localStorage data are lost but user still persisted in redux store, hydrate user
			const sessionToken = store.getState().user.sessionToken;
			if (sessionToken && !Parse.User.current()) {
				store.dispatch(hydrateUser(sessionToken) as any);
			}
		}
	};

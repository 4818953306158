import Parse from "parse";

/**
 * build Parse User headers
 * @returns headers required to represent a Pase User
 */
export const buildUserHeaders = () => {
	const user = Parse.User.current();
	const sessionToken = user?.getSessionToken();

	return {
		"X-Session-Token": sessionToken,
	};
};

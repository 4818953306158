import * as parseUtils from "../../utils/parseUtils.js";

/* Actions */
const ACTIONS = {
	ADMIN_PROCESSING: "ADMIN_PROCESSING",
	RECEIVE_ADMIN_PRODUCTS: "RECEIVE_ADMIN_PRODUCTS",
	ADMIN_ERROR: "ADMIN_ERROR",
};

const action = (type, payload) => ({
	type,
	payload,
});

/* Reducer */
const initialState = {
	assignableProducts: undefined,
	processing: false,
	error: undefined,
};

export const getAssignableProducts = () => {
	return (dispatch, getState) => {
		// dispatch(action(ACTIONS.ADMIN_PROCESSING));
		parseUtils.assignableProducts((err, productData) => {
			if (err) {
				dispatch(action(ACTIONS.ADMIN_ERROR, err));
			}
			else {
				dispatch(action(ACTIONS.RECEIVE_ADMIN_PRODUCTS, productData));
			}
		});
	};
};

// eslint-disable-next-line no-shadow
const adminReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.ADMIN_PROCESSING:
			return {
				...state,
				adminError: undefined,
				processing: true,
			};
		case ACTIONS.RECEIVE_ADMIN_PRODUCTS: {
			return {
				...state,
				assignableProducts: action.payload,
				adminError: undefined,
				processing: false,
			};
		}
		case ACTIONS.ADMIN_ERROR: {
			return {
				...state,
				adminError: action.payload,
				processing: false,
			};
		}
		default:
			return state;
	}
};

export default adminReducer;

// combine and export reducers here

import { combineReducers } from "redux";
import applicationReducer from "./recruiter/Applications/redux";
import authReducer from "./auth/redux";
import dynamicFormReducer from "./common/redux";
import invitationReducer from "./recruiter/invitations/redux";
import pilotApplicationReducer from "./pilot/PilotApplications/redux";
import uiReducer from "./ui/redux";

const resetState = (state, action) => {
	// Reset only vitae reducer's state when individual/organization state is changed (excl. authReducer)
	if (action.type === "VITAE_AUTH_INDIVIDUAL" || action.type === "VITAE_AUTH_ORGANIZATION") {
		const authReducerState = state.authReducer;
		return ({ authReducer: authReducerState });
	}
	
	return state;
};

const vitaeReducers = (state, action) => combineReducers({
	applicationReducer,
	authReducer,
	dynamicFormReducer,
	invitationReducer,
	pilotApplicationReducer,
	uiReducer,
})(resetState(state, action), action);

export default vitaeReducers;

import vitaeRequest from "../../../utils/vitaeRequest";

export const fetchInvitations = async () => {
	return vitaeRequest("invitations", "GET");
};
export const createInvitation = async (attributes, applicationTemplateId) => {
	const data = [{
		type: "invitation",
		attributes,
		relationships: {
			applicationTemplate: {
				data: {
					type: "applicationTemplate",
					id: applicationTemplateId,
				},
			},
		},
	}];
	return vitaeRequest("invitations", "POST", { data });
};
export const resendInvitation = async (id) => {
	return vitaeRequest(`invitations/${id}/resend`, "POST");
};
export const removeInvitation = async (id) => {
	return vitaeRequest(`invitations/${id}`, "DELETE");
};

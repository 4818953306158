const ACTIONS = {
	INTEGRATE_KNOWN_USER: "INTEGRATE_KNOWN_USER",
};

const initialState = {
	integrateKnownUser: false,
};

const action = (type, payload) => ({
	type,
	payload,
});

export const integrateKnownUser = () => (dispatch) => {
	dispatch(action(ACTIONS.INTEGRATE_KNOWN_USER));
};

// eslint-disable-next-line no-shadow
const integrateReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.INTEGRATE_KNOWN_USER:
			return {
				...state,
				integrateKnownUser: true,
			};

		default:
			return state;
	}
};

export default integrateReducer;

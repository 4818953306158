import * as parseUtils from "../../utils/parseUtils.js";

/* Actions */
export const ACTIONS = {
	SSO_PROCESSING: "SSO_PROCESSING",
	SSO_RECEIVED: "SSO_RECEIVED",
	SSO_ERROR: "SSO_ERROR",
};

const action = (type, tokenType, payload) => ({
	type,
	tokenType,
	payload,
});

const initialState = {
	url: { payload: {}, processing: false, error: undefined },
	svcs: { payload: {}, processing: false, error: undefined },
};

export const ssoAuth = url => async dispatch => {
	dispatch(action(ACTIONS.SSO_PROCESSING, "url"));
	try {
		const payload = await parseUtils.ssoAuth(url);
		dispatch(action(ACTIONS.SSO_RECEIVED, "url", payload));
	}
	catch (error) {
		const parsedError = parseUtils.handleParseError(error);
		dispatch(action(ACTIONS.SSO_ERROR, "url", parsedError));
	}
};

export const ssoServicesApiAuth = () => async dispatch => {
	dispatch(action(ACTIONS.SSO_PROCESSING, "svcs"));
	try {
		const payload = await parseUtils.servicesApiAuth();
		dispatch(action(ACTIONS.SSO_RECEIVED, "svcs", payload));
	}
	catch (error) {
		const parsedError = parseUtils.handleParseError(error);
		dispatch(action(ACTIONS.SSO_ERROR, "svcs", parsedError));
	}
};

// eslint-disable-next-line no-shadow
const ssoReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.SSO_PROCESSING:
			return {
				...state,
				[action.tokenType]: {
					payload: {},
					error: undefined,
					processing: true,
				},
			};
		case ACTIONS.SSO_RECEIVED: {
			return {
				...state,
				[action.tokenType]: {
					payload: action.payload,
					error: undefined,
					processing: false,
				},
			};
		}
		case ACTIONS.SSO_ERROR: {
			return {
				...state,
				[action.tokenType]: {
					payload: {},
					error: action.payload,
					processing: false,
				},
			};
		}
		default:
			return state;
	}
};

export default ssoReducer;

import * as services from "../services/invitations";
import types from "./actionTypes";

export const fetchInvitations = () => ({
	type: types.FETCH_INVITATIONS,
	payload: services.fetchInvitations(),
});

export const createInvitation = (attributes, applicationTemplateId) => ({
	type: types.CREATE_INVITATION,
	payload: services.createInvitation(attributes, applicationTemplateId),
});

export const resendInvitation = (id) => ({
	type: types.RESEND_INVITATION,
	payload: services.resendInvitation(id),
});
	
export const removeInvitation = (id) => ({
	type: types.REMOVE_INVITATION,
	payload: services.removeInvitation(id),
	meta: { id },
});

export const filterInvitations = (filteredData) => ({
	type: types.FILTER_INVITATIONS,
	filteredData,
});

export const clearFilter = () => ({
	type: types.CLEAR_FILTER,
});

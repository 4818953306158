import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { Tou, TouUserAcceptance } from "../api/models/tou";
import { getTou } from "../api/tou";
import { defaultQueryOptions } from "../lib/realm/domain/shared/queryOptions";
import { TOU } from "./constants";

export const useGetTou = (options?: UseQueryOptions<Tou, AxiosError>) => {
	const query = useQuery(TOU, getTou, defaultQueryOptions(options));
	return {
		...query,
		touAccepted: query.data?.user_acceptance === TouUserAcceptance.Valid,
	};
};

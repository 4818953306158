import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import flattenDeep from "lodash/flattenDeep";
import { ROLES } from "./utils/authority";
import { UsersIcon, LogbookConverterIcon, CouponsIcon, DashboardIcon, LinksIcon, FileIcon, VitaeProfileIcon } from "./assets/icons";
import { SidebarNavItemData } from "@coradine/web-ui";
import { ReduxStore } from "./store/types";

export type SidebarNavItems = SidebarNavItemData & { isDynamic?: boolean };

export const allCustomersItems: SidebarNavItems[] = [
	{ label: "Account", to: "/account", icon: <DashboardIcon />, component: "SidebarNavItem" },

	// https://coradine.atlassian.net/browse/WEB-1106 - hides "Coradine Vitae" and "Pilot Profile" from menu
	/*
	{ label: "Coradine Vitae", to: "/vitae/", icon: <VitaeProfileIcon />, component: "SidebarNavDropdown", subItems: [
		{ label: "Pilot Profile", to: "/vitae/profile", component: "SidebarNavItem" },

		// TODO: VIT-893 - Temporarily remove pilot search page from Navbar until page is available
		// { label: "Pilot Search", to: "/vitae/search", component: "SidebarNavItem" },
	] },
	*/

];

export const supportItems: SidebarNavItems[] = [
	{ title: true, label: "Support Tools", component: "SidebarNavTitle" },
	{ label: "User Tools", to: "/jedi-basic-tools", icon: <UsersIcon />, component: "SidebarNavItem" },
	{ label: "Logbook Conversion", to: "/logbook-tools", icon: <LogbookConverterIcon />, component: "SidebarNavItem" },
	{ label: "File Retrieval", to: "/file-tools", icon: <FileIcon />, component: "SidebarNavItem" },
];

export const marketingItems: SidebarNavItems[] = [
	{ title: true, label: "Marketing Tools", component: "SidebarNavTitle" },
	{ label: "Coupon Codes", to: "/coupons", icon: <CouponsIcon />, component: "SidebarNavItem" },
	{ label: "Persistent Links", to: "/persistent-links", icon: <LinksIcon />, component: "SidebarNavItem" },
];

export const vitaeRecruiter: SidebarNavItems[] = [
	{ title: true, label: "Vitae Recruiter", component: "SidebarNavTitle" },
	{ label: "Coradine Vitae", to: "/vitae/", icon: <VitaeProfileIcon />, component: "SidebarNavDropdown", subItems: [
		{ label: "Pilot Search", to: "/vitae/search", component: "SidebarNavItem" },
	] },
	{ label: "Invitations", to: "/invitations", component: "SidebarNavItem" },
	{ label: "Applications", to: "/applications", component: "SidebarNavItem" },
];

export const vitaePilot: SidebarNavItems[] = [
	{ title: true, label: "Vitae Pilot", component: "SidebarNavTitle" },
	{ label: "Pilot Applications", to: "/pilot-applications/", isDynamic: true, component: "SidebarNavDropdown", subItems: [
		{ label: "Welcome", to: "/pilot-applications", component: "SidebarNavItem" },
	] },
];


function navGroupsForRole(role: string) {
	switch (role) {
		case ROLES.ADMIN:
			return [...supportItems, ...marketingItems];

		case ROLES.VITAE.RECRUITER:
			return vitaeRecruiter;

		case ROLES.VITAE.PILOT:
			return vitaePilot;
		default:
			return [];
	}
}

const navItemsForRoles = (roles: string[]) => roles.map(role => navGroupsForRole(role));

export const navigation = (roles: string[]): SidebarNavConfig => {
	if (roles && roles.length) {
		const rolesItems = navItemsForRoles(roles);
		return { items: flattenDeep([...allCustomersItems, ...rolesItems]) };
	}
	return { items: allCustomersItems };
};


/**
 * Builds full dynamic navigation list
 * @param {NavItem[]} subItems sub-items of a specific NavItem
 * @param {string[]} userRoles
 */
function buildDynamicNavigation(subItems: SidebarNavItems[] | null, userRoles: string[]): SidebarNavItems[] {
	const filteredNav = navigation(userRoles);
	const dynamicNavigation = filteredNav.items.map((navItem) => {
		if (navItem.isDynamic && subItems) {
			return { ...navItem, subItems: [...(navItem.subItems || []), ...subItems] };
		}
		return navItem;
	});
	return dynamicNavigation;
}

const path = "/pilot-applications";

/**
 * Builds Vitae Dynamic Navigation items for Pilot Applications.
 * @param {FormItem[]} formStructure
 * @returns {NavItem[]} list of Pilot Application dynamic navigation items including "Review & Submit"
 */
function buildVitaeChildren(formStructure): SidebarNavItems[] | null {
	if (formStructure && formStructure.length) {
		const subItems: SidebarNavItems[] = formStructure.map((item, index) => {
			const child = {
				label: item.title,
				to: `${path}/${index}`,
				component: "SidebarNavItem",
			};
			return child;
		});

		// Append Submit & Review nav item
		const submitAndReview: SidebarNavItems = {
			label: "Review & Submit",
			to: `${path}/submit`,
			component: "SidebarNavItem",
		};
		const updatedChildren = [...subItems, submitAndReview];
		return updatedChildren;
	}
	return null;
}

export type SidebarNavConfig = { items: SidebarNavItems[] };

/**
 * Custom hook that outputs sidebar nav items for the current user roles.
 * Nav items could be either static (pre-defined) or dynamic as for Vitae
 * @param {string[]} userRoles
 * @returns {SidebarNavItems[]} containing the list of navigation items.
 */
export const useSidebarNavItems = (userRoles: string[]): SidebarNavConfig => {
	const [dynamicNav, setDynamicNav] = useState<SidebarNavItems[] | undefined>();

	const formStructure = useSelector((state: ReduxStore) => state.vitae.dynamicFormReducer.formStructure);

	useEffect(() => {
		// 1. Build Children of the Vitae Dynamic Menu
		const updatedChildren = buildVitaeChildren(formStructure);

		// 2. Append Children to the navigation list appropriately
		const dynNav = buildDynamicNavigation(updatedChildren, userRoles);
		setDynamicNav(dynNav);
	}, [userRoles, formStructure]);

	const navConfig = dynamicNav ? { items: dynamicNav } : navigation(userRoles);
	return navConfig;
};

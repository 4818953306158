export const ROLES = {
	ADMIN: "admin",
	VITAE: {
		PILOT: "vitae-applicant",
		RECRUITER: "vitae-recruiter",
	},
};

export const isAllowed = (user, rights) => rights.some(right => user.rights.includes(right));

export const hasRole = (userRoles = [], roles = []) => {
	if (roles.length) {
		return roles.some(role => userRoles.includes(role));
	}
	return true;
};

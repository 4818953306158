import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider } from "react-query";
import { lightTheme, ToastContainer } from "@coradine/web-ui";

// @ts-ignore - No TS types defined by package
import initReactFastclick from "react-fastclick";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import Root from "./Root";
import App from "./App";
import { queryClient } from "./utils/reactQueryClient";

initReactFastclick();

ReactDOM.render(
	<React.StrictMode>
		<Root>
			<QueryClientProvider client={queryClient}>
				{/* By default, React Query Devtools are not included in production bundles
			when process.env.NODE_ENV === 'production', so we don't need to worry about
			excluding them during a production build.*/}
				<ReactQueryDevtools initialIsOpen={false} />
				<StylesProvider injectFirst>
					<MuiThemeProvider theme={lightTheme}>
						<ThemeProvider theme={lightTheme}>
							<CssBaseline />
							<ToastContainer />
							<App />
						</ThemeProvider>
					</MuiThemeProvider>
				</StylesProvider>
			</QueryClientProvider>
		</Root>
	</React.StrictMode>,
	document.getElementById("root"),
);

// Allow app to work offline
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { axios } from "./utils/axios";
import Axios, { AxiosError } from "axios";
import { Tou, TouUserAcceptance } from "./models/tou";
import { touUrl } from "./routes";
import { buildUserHeaders } from "./utils/buildHeaders";

export const getTou = async (): Promise<Tou> => {
	const headers = { headers: buildUserHeaders() };

	try {
		const { data } = await axios.get(touUrl, headers);
		return data?.data as Tou;
	}
	catch (error) {
		const err = error as AxiosError | Error;
		if (Axios.isAxiosError(err) && err.response?.status === 304) {
			return { user_acceptance: TouUserAcceptance.Valid };
		}
		throw err;
	}
};

export const acceptTou = async (): Promise<void> => {
	const headers = { headers: buildUserHeaders() };
	return axios.post(touUrl, null, headers);
};

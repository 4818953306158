import * as parseUtils from "../../utils/parseUtils.js";

/* Actions */
const ACTIONS = {
	NEWSLETTER_LISTS_PROCESSING: "NEWSLETTER_LISTS_PROCESSING",
	NEWSLETTER_LISTS_RECEIVED: "NEWSLETTER_LISTS_RECEIVED",
	NEWSLETTER_LISTS_ERROR: "NEWSLETTER_LISTS_ERROR",
};

const action = (type, payload) => ({
	type,
	payload,
});

export const fetchNewsletterLists = () => dispatch => {
	dispatch(action(ACTIONS.NEWSLETTER_LISTS_PROCESSING));
	parseUtils.fetchNewsletterLists((err, result) => {
		if (err) {
			dispatch(action(ACTIONS.NEWSLETTER_LISTS_ERROR, err));
		}
		else {
			dispatch(action(ACTIONS.NEWSLETTER_LISTS_RECEIVED, result));
		}
	});
};

const initialState = {
	newsletter_loading: false,
	lists: undefined,
	lists_error: undefined,
};

// eslint-disable-next-line no-shadow
const couponReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.NEWSLETTER_LISTS_PROCESSING:
			return {
				...state,
				lists: undefined,
				lists_error: undefined,
				lists_loading: true,
			};
		case ACTIONS.NEWSLETTER_LISTS_RECEIVED:
			return {
				...state,
				lists: action.payload,
				lists_error: undefined,
				lists_loading: false,
			};

		case ACTIONS.NEWSLETTER_LISTS_ERROR:
			return {
				...state,
				lists: undefined,
				lists_error: action.payload,
				lists_loading: false,
			};
		default:
			return state;
	}
};

export default couponReducer;

// Dynamic Form type constants
const FETCH_APPLICATIONS = "FETCH_APPLICATIONS";
const FETCH_APPLICATION_DATA = "FETCH_APPLICATION_DATA";
const FETCH_APPLICATION_TEMPLATE = "FETCH_APPLICATION_TEMPLATE";
const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";
const UPLOAD_FILE = "UPLOAD_FILE";
const DELETE_FILE = "DELETE_FILE";
const FETCH_FILE = "FETCH_FILE";
const SAVE_FILE_BLOB = "SAVE_FILE_BLOB";
const SET_TABLE_DATA = "SET_TABLE_DATA";
const SET_APPLICATION_IDS = "SET_APPLICATION_IDS";
const SET_PATCH_HISTORY = "SET_PATCH_HISTORY";
const UPDATE_PATCH_HISTORY = "UPDATE_PATCH_HISTORY";
const RESET_PATCH_HISTORY = "RESET_PATCH_HISTORY";
const ROLLBACK_PATCH_HISTORY = "ROLLBACK_PATCH_HISTORY";
const PATCH_LOCAL_APPLICATION_DATA = "PATCH_LOCAL_APPLICATION_DATA";

export default {
	FETCH_APPLICATIONS,
	FETCH_APPLICATION_DATA,
	FETCH_APPLICATION_TEMPLATE,
	UPDATE_FORM_DATA,
	UPLOAD_FILE,
	DELETE_FILE,
	FETCH_FILE,
	SAVE_FILE_BLOB,
	SET_TABLE_DATA,
	SET_APPLICATION_IDS,
	SET_PATCH_HISTORY,
	UPDATE_PATCH_HISTORY,
	RESET_PATCH_HISTORY,
	ROLLBACK_PATCH_HISTORY,
	PATCH_LOCAL_APPLICATION_DATA,
};

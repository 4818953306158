import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { ReactComponent as CheckmarkCircle } from "./checkmark-circle.svg";
import { ReactComponent as ChevronDown } from "./chevron-down.svg";
import { ReactComponent as ChevronDownXXSmall } from "./chevron-down-xxsmall.svg";
import { ReactComponent as ChevronLeftXXSmall } from "./chevron-left-xxsmall.svg";
import { ReactComponent as ChevronRight } from "./chevron-right.svg";
import { ReactComponent as ChevronRightXXSmall } from "./chevron-right-xxsmall.svg";
import { ReactComponent as Coupons } from "./coupons.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";
import { ReactComponent as EyeOpen } from "./eye-open.svg";
import { ReactComponent as EyeClosed } from "./eye-closed.svg";
import { ReactComponent as File } from "./file.svg";
import { ReactComponent as JobOffersOff } from "./job-offers-off.svg";
import { ReactComponent as JobOffersOn } from "./job-offers-on.svg";
import { ReactComponent as Links } from "./links.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as LogbookConverter } from "./logbook-converter.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as LogTen } from "./logten.svg";
import { ReactComponent as LTConnectionOff } from "./lt-connection-off.svg";
import { ReactComponent as LTConnectionOn } from "./lt-connection-on.svg";
import { ReactComponent as Mail } from "./mail.svg";
import { ReactComponent as NoSignOutlined } from "./no-sign-outlined.svg";
import { ReactComponent as PersonCircle } from "./person-circle.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as Suitcase } from "./suitcase.svg";
import { ReactComponent as Users } from "./users.svg";
import { ReactComponent as VitaeProfile } from "./vitae-profile.svg";
import { ReactComponent as Warning } from "./warning.svg";
import { ReactComponent as WavingHand } from "./waving-hand.svg";

const xxSmallIconProps = {
	style: { fontSize: 10 },
	viewBox: "0 0 10 10",
};

export const CheckmarkCircleIcon = (props: SvgIconProps) => <SvgIcon component={CheckmarkCircle} {...props} />;
export const ChevronDownIcon = (props: SvgIconProps) => <SvgIcon component={ChevronDown} {...props} />;
export const ChevronDownXXSmallIcon = (props: SvgIconProps) => <SvgIcon component={ChevronDownXXSmall} {...xxSmallIconProps} {...props} />;
export const ChevronLeftXXSmallIcon = (props: SvgIconProps) => <SvgIcon component={ChevronLeftXXSmall} {...xxSmallIconProps} {...props} />;
export const ChevronRightIcon = (props: SvgIconProps) => <SvgIcon component={ChevronRight} {...props} />;
export const ChevronRightXXSmallIcon = (props: SvgIconProps) => <SvgIcon component={ChevronRightXXSmall} {...xxSmallIconProps} {...props} />;
export const CouponsIcon = (props: SvgIconProps) => <SvgIcon component={Coupons} {...props} />;
export const DashboardIcon = (props: SvgIconProps) => <SvgIcon component={Dashboard} {...props} />;
export const EyeOpenIcon = (props: SvgIconProps) => <SvgIcon component={EyeOpen} {...props} />;
export const EyeClosedIcon = (props: SvgIconProps) => <SvgIcon component={EyeClosed} {...props} />;
export const FileIcon = (props: SvgIconProps) => <SvgIcon component={File} {...props} />;
export const LTConnectionOnIcon = (props: SvgIconProps) => <SvgIcon component={LTConnectionOn} {...props} />;
export const LTConnectionOffIcon = (props: SvgIconProps) => <SvgIcon component={LTConnectionOff} {...props} />;
export const JobOffersOffIcon = (props: SvgIconProps) => <SvgIcon component={JobOffersOff} {...props} />;
export const JobOffersOnIcon = (props: SvgIconProps) => <SvgIcon component={JobOffersOn} {...props} />;
export const LinksIcon = (props: SvgIconProps) => <SvgIcon component={Links} {...props} />;
export const LocationIcon = (props: SvgIconProps) => <SvgIcon component={Location} {...props} />;
export const LogbookConverterIcon = (props: SvgIconProps) => <SvgIcon component={LogbookConverter} {...props} />;
export const LogoutIcon = (props: SvgIconProps) => <SvgIcon component={Logout} {...props} style={{ ...props.style, fill: "none" }} viewBox="0 0 20 20" />;
export const LogTenIcon = (props: SvgIconProps) => <SvgIcon component={LogTen} {...props} />;
export const MailIcon = (props: SvgIconProps) => <SvgIcon component={Mail} {...props} />;
export const NoSignOutlinedIcon = (props: SvgIconProps) => <SvgIcon component={NoSignOutlined} {...props} />;
export const PersonCircleIcon = (props: SvgIconProps) => <SvgIcon component={PersonCircle} {...props} />;
export const PhoneIcon = (props: SvgIconProps) => <SvgIcon component={Phone} {...props} />;
export const SuitcaseIcon = (props: SvgIconProps) => <SvgIcon component={Suitcase} {...props} />;
export const UsersIcon = (props: SvgIconProps) => <SvgIcon component={Users} {...props} />;
export const VitaeProfileIcon = (props: SvgIconProps) => <SvgIcon component={VitaeProfile} {...props} />;
export const WarningIcon = (props: SvgIconProps) => <SvgIcon component={Warning} {...props} />;
export const WavingHandIcon = (props: SvgIconProps) => <SvgIcon component={WavingHand} {...props} />;

import * as parseUtils from "../../utils/parseUtils";

/* Actions */
export const ACTIONS = {
	REDEEM_PROCESSING: "REDEEM_PROCESSING",
	REDEEM_SUCCESS: "REDEEM_SUCCESS",
	REDEEM_ERROR: "REDEEM_ERROR",
	REDEEM_CLEAR: "REDEEM_CLEAR",
};

const action = (type, payload) => ({
	type,
	payload,
});

export const clearRedeemed = () => action(ACTIONS.REDEEM_CLEAR);

export const redeem = couponCode => dispatch => {
	// dispatch notification informing of redemption start
	dispatch(action(ACTIONS.REDEEM_PROCESSING));

	parseUtils.redeem(couponCode, (err, result) => {
		if (err) {
			dispatch(action(ACTIONS.REDEEM_ERROR, err));
		}
		else {
			dispatch(action(ACTIONS.REDEEM_SUCCESS, result));
		}
	});
};

/* Reducer */
const initialState = {
	processing: false,
	error: undefined,
	result: undefined,
};

// eslint-disable-next-line no-shadow
const redeemReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.REDEEM_PROCESSING:
			return {
				...state,
				result: undefined,
				error: undefined,
				processing: true,
			};

		case ACTIONS.REDEEM_ERROR:
			return {
				...state,
				result: undefined,
				error: action.payload,
				processing: false,
			};

		case ACTIONS.REDEEM_SUCCESS:
			return {
				...state,
				error: undefined,
				processing: false,
				result: action.payload,
			};

		case ACTIONS.REDEEM_CLEAR:
			return { ...initialState };

		default:
			return state;
	}
};

export default redeemReducer;

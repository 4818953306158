import types from "./actionTypes";

// Auth
export const authRequest = () => ({
	type: types.AUTH_REQUEST,
});

export const saveToken = (token, type, membershipId = null) => {
	switch (type) {
		case "auth":
			return { type: types.SAVE_AUTH_TOKEN, token };
		case "individual":
			return { type: types.VITAE_AUTH_INDIVIDUAL, token };
		case "organization":
			return { type: types.VITAE_AUTH_ORGANIZATION, token, membershipId };
		default:
			return null;
	}
};

export const setAuthError = (error) => ({
	type: types.AUTH_ERROR,
	error,
});

// Memberships
export const membershipsRequest = () => ({
	type: types.RETRIEVE_MEMBERSHIPS_REQUEST,
});
				
export const saveMemberships = (memberships) => ({
	type: types.RETRIEVE_MEMBERSHIPS_SUCCESS,
	memberships,
});

export const setMembershipsError = (error) => ({
	type: types.RETRIEVE_MEMBERSHIPS_ERROR,
	error,
});

export const populateAuthInitialState = () => ({
	type: types.POPULATE_AUTH_INITIAL_STATE,
});

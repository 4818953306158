import React, { MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, IconButton, makeStyles } from "@material-ui/core";
import { Dropdown, ListItem } from "@coradine/web-ui";
import operations from "../../../Vitae/auth/redux/operations";
import { ChevronDownIcon, LogoutIcon } from "../../../assets/icons";
import { ReduxStore } from "../../../store/types";

const useStyles = makeStyles({
	avatar: {
		width: 29,
		height: 29,
		marginRight: 2,
	},
	button: {
		paddingRight: 0,
	},
	accountDropdown: {
		position: "relative",
		top: -3,
		right: 12,
	},
});

const AccountDropdown = () => {
	const dispatch = useDispatch();
	const memberships = useSelector((store: ReduxStore) => store.vitae.authReducer.memberships);
	const activeMembership = useSelector((store: ReduxStore) => store.vitae.authReducer.activeMembership);
	const userEmail = useSelector((state: ReduxStore) => state.user.userEmail) || "";

	const history = useHistory();
	const classes = useStyles();

	const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

	const toggleAccountDropdown = (event: MouseEvent<HTMLElement>) => setAnchor(anchor ? null : event.currentTarget );

	const changeAccount = (isActive: boolean, membershipId?: string) => {
		if (isActive) {
			return null;
		}
		if (membershipId) {
			return dispatch(operations.authenticateOrganization(membershipId));
		}
		return dispatch(operations.refreshIndividualPermissions());
	};

	const getOrganizationAccounts = (): ListItem[] => memberships && Object.values(memberships).map((membership) => {
		const { id } = membership.relationships.organization.data;

		const membershipId = membership.id;
		const selected = activeMembership === membershipId;

		return {
			variant: "withAvatar", label: id, selected, onClick: () => changeAccount(selected, membershipId),
		};
	});

	
	const listStructure: ListItem[] = [
		{ variant: "header", label: "ACCOUNTS" },
		{ variant: "withAvatar", label: userEmail, selected: !activeMembership, onClick: () => changeAccount(!activeMembership) },
		...getOrganizationAccounts(),
		{ variant: "header", label: "OTHER" },
		{ variant: "withIcon", startIcon: <LogoutIcon />, label: "Log Out", onClick: () => history.push("/logout") },
	];

	return (
		<div data-cy="account-dropdown">
			<IconButton
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={toggleAccountDropdown}
				color="inherit"
				className={classes.button}
			>
				<Avatar className={classes.avatar} />
				<ChevronDownIcon style={{ fontSize: 12, marginLeft: 8 }} />
			</IconButton>
			<Dropdown
				anchorEl={anchor}
				open={Boolean(anchor)}
				listStructure={listStructure}
				onClose={toggleAccountDropdown}
				className={classes.accountDropdown}
			/>
		</div>
	);
};

export default AccountDropdown;

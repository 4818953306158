import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { fetchBrand } from "../store/model/brand";

function parseBrandFromPathname(pathname: string) {
	const pathComps = pathname.split("/");
	const brand = pathComps.length > 2 && pathComps[1] === "redeem" ? pathComps[2] : undefined;
	return brand || undefined;
}

const BrandFetch: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const { location: { pathname } } = props;
	useEffect(() => {
		const brand = parseBrandFromPathname(pathname);
		dispatch(fetchBrand(brand));
	}, [dispatch, pathname]);

	return <div />;
};

export default withRouter(BrandFetch);

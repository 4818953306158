import vitaeRequest from "../../utils/vitaeRequest";

export const uploadFile = async (applicationDataId, keys, file) => {
	return vitaeRequest(`applicationData/${applicationDataId}/files?path=data.${keys.join(".")}`, "POST", file, {
		"Content-Type": "multipart/form-data",
	});
};

export const deleteFile = async (applicationDataId, keys) => vitaeRequest(`applicationData/${applicationDataId}/files?path=data.${keys.join(".")}`, "DELETE");

export const fetchFile = async (applicationDataId, keys) => vitaeRequest(`applicationData/${applicationDataId}/files?path=data.${keys.join(".")}`, "GET", null, null, "blob").then(blob => ({ keys, blob }));

/* eslint-disable no-underscore-dangle */
import jwtDecode from "jwt-decode";
import AUTH_ACTIONS from "../../Vitae/auth/redux/actionTypes";
import { ACTIONS as USER_ACTIONS } from "./user";
import { adminStorageKey, vitaeAuthTokenKey } from "../../utils/localStorage";
import { ROLES } from "../../utils/authority";

const getAdminRoles = (isAdmin) => {
	if (isAdmin) {
		// Since we only have a single admin role for the moment, we return a single item.
		// In the future, admins would have different roles (ie: Customer Service, Marketing, Eng ... etc)
		// This array can then be populated with the appropriate role identifiers/
		return [ROLES.ADMIN];
	}
	return [];
};

const getVitaeRoles = (token) => {
	if (token) {
		const decoded = jwtDecode(token);
		return decoded.prm;
	}
	return [];
};

const initialState = (() => {
	const adminKey = localStorage.getItem(adminStorageKey);
	const vitaeToken = localStorage.getItem(vitaeAuthTokenKey);

	const adminRoles = getAdminRoles(adminKey);
	const vitaeRoles = getVitaeRoles(vitaeToken);

	return {
		adminRoles,
		vitaeRoles,
		roles: [...adminRoles, ...vitaeRoles],
		permissions: [],
	};
})();

const authorityReducer = (state = initialState, action) => {
	switch (action.type) {
		case USER_ACTIONS.RECEIVE_USER: {
			const { isAdmin, sessionToken } = action.payload;
			if (isAdmin) {
				localStorage.setItem(adminStorageKey, sessionToken);
			}
			const adminRoles = getAdminRoles(isAdmin);
			return {
				...state,
				adminRoles,
				roles: [...adminRoles, ...state.vitaeRoles],
			};
		}
		case AUTH_ACTIONS.VITAE_AUTH_INDIVIDUAL:
		case AUTH_ACTIONS.VITAE_AUTH_ORGANIZATION: {
			const vitaeRoles = getVitaeRoles(action.token);
			return {
				...state,
				vitaeRoles,
				roles: [...state.adminRoles, ...vitaeRoles],
			};
		}
		case USER_ACTIONS.USER_LOGOUT: {
			localStorage.removeItem(adminStorageKey);
			return initialState;
		}
		default:
			return state;
	}
};

export default authorityReducer;

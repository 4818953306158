import React from "react";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducer as formReducer } from "redux-form";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";

import userReducer, { populateUserInitialState } from "./store/model/user";
import integrateReducer from "./store/model/integrate";
import brandReducer from "./store/model/brand";
import redeemReducer from "./store/model/redeem";
import adminReducer from "./store/model/admin";
import couponReducer from "./store/model/coupon";
import newsletterReducer from "./store/model/newsletter";
import ssoReducer from "./store/model/sso";
import authorityReducer from "./store/model/authority";
import vitaeReducer from "./Vitae/rootReducer";

import { populateAuthInitialState } from "./Vitae/auth/redux/actions";
import { onRehydrationMiddleware } from "./store/middlewares/onRehydration";

const persistConfig = {
	key: "root",
	storage,
	blacklist: ["redeem", "integrate", "sso"],
};

export const reducers = (state, action) => combineReducers({
	user: userReducer,
	integrate: integrateReducer,
	brand: brandReducer,
	redeem: redeemReducer,
	admin: adminReducer,
	coupon: couponReducer,
	form: formReducer,
	newsletter: newsletterReducer,
	sso: ssoReducer,
	authority: authorityReducer,
	vitae: vitaeReducer,
})(action.type === "USER_LOGOUT" ? undefined : state, action);

// Build up middleware
export const middlewares = [thunk, promise, onRehydrationMiddleware];

// eslint-disable-next-line no-underscore-dangle
let _store = undefined;
export const store = () => _store;

const Root = (props) => {
	const { children, initialState = {} } = props;
	const persistedReducer = persistReducer(persistConfig, reducers);

	const isCypress = window.Cypress;

	_store = createStore(isCypress ? reducers : persistedReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
	_store.dispatch(populateUserInitialState());
	_store.dispatch(populateAuthInitialState());

	const persistor = persistStore(_store);

	// expose store when run in Cypress
	if (isCypress) {
		window.store = _store;
	}
	return isCypress ? <Provider store={_store}>{children}</Provider> : <PersistGate loading={null} persistor={persistor}><Provider store={_store}>{children}</Provider></PersistGate>;
};

// This file simplifies the use/import of Redux Provider inside index.js & test files where connect() is used.
export default Root;

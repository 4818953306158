
// This UI Reducer is inspired from Hackernoon's article: Handling Loading Actions: The Proper Way in Redux
// https://hackernoon.com/handling-loading-actions-the-proper-way-in-redux-t3k36e8

// The current redux implementation enables `redux-promise-middleware`, which given a single action with an async payload, it
// transforms the action to a separate pending action and a separate fulfilled/rejected action, representing the states of the async action.

// const asyncAction = () => ({
//   type: "ASYNC_ACTION",
//   payload: new Promise(...),
// })
	
// The uiReducer will then intercept the following action types:
// "ASYNC_ACTION_PENDING", "ASYNC_ACTION_FULFILLED" and "ASYNC_ACTION_REJECTED" which are auto-magically generated by the promise middleware.
// and store their payload (payload can be used as success/errors).
// The status of each action type is saved in the "status" attribute which can be used in a custom selector to generate loading flags.
	
export const STATUS = {
	PENDING: "PENDING",
	FULFILLED: "FULFILLED",
	REJECTED: "REJECTED",
};

const uiReducer = (state = {}, action) => {
	const { type, payload } = action;
	const matches = new RegExp(`(.*)_(${STATUS.PENDING}|${STATUS.FULFILLED}|${STATUS.REJECTED})`, "u").exec(type);
	
	if (!matches) {
		return state;
	}
	
	const requestPrefix = matches[1];
	const requestState = matches[2];

	return {
	  ...state,

	  // Store request's status and payload
	  [requestPrefix]: {
		  status: requestState,
		  payload,
	  },
	};
};

export default uiReducer;

import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { LayoutContent, LayoutSidebar, SidebarNavTitle, SidebarNavItem, SidebarNavDropdown } from "@coradine/web-ui";

// routes config
import routes from "../../routes";

import DefaultHeader from "./DefaultHeader";
import DefaultFooter from "./DefaultFooter";
import { useSidebarNavItems } from "../../sidebarNavConfig";
import { ReduxStore } from "../../store/types";
import { useGetTou } from "../../hooks/useGetTou";

const Loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

interface DefaultLayoutProps {
	noSidebar?: boolean;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = (props) => {
	const { noSidebar, children } = props;

	const location = useLocation();

	const loggedIn = Boolean(useSelector((state: ReduxStore) => state.user.sessionToken));
	const userRoles: string[] = useSelector((state: ReduxStore) => state.authority.roles);

	const { isLoading, touAccepted } = useGetTou({ enabled: loggedIn });

	const routesForRoles = routes(userRoles);

	const [open, setOpen] = useState(!noSidebar);

	const toggleSidebar = () => setOpen(!open);

	const renderRoutes = () => {
		// Wait until loading is finished before rendering routes
		if (isLoading) {
			return null;
		}

		// Redirect to /login if user is logged out or hasn't yet accepted the latest ToU.
		if (loggedIn && touAccepted) {
			// If <DefaultLayout /> is not used specifically in another component (by the use of children prop), then render routes from routes.js
			return (
				children || (
					<Switch>
						{routesForRoles.map(
							(route, idx) => route.component && (
								<Route
									key={route.key || idx}
									path={route.path}
									exact={route.exact}
									render={(routeProps) => <route.component {...routeProps} />}
								/>
							),
						)}
						{/* Redirect to account page when visiting an undefined or restricted route */}
						<Redirect from="/" to="/account" />
					</Switch>
				)
			);
		}
		return <Redirect to={{ pathname: "/login", state: { original: location } }} />;
	};

	const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	useEffect(() => {
		// Automatically hide Sidebar on small devices
		if (!noSidebar) {
			setOpen(!matches);
		}
	}, [matches, noSidebar]);

	const isShifted = !matches && open;

	const { items } = useSidebarNavItems(userRoles);

	return (
		<div style={{ display: "flex" }}>
			<DefaultHeader isShifted={isShifted} toggleSidebar={toggleSidebar} />
			<LayoutSidebar
				navConfig={items}
				open={open}
				components={{ SidebarNavTitle, SidebarNavItem, SidebarNavDropdown }}
				onClose={toggleSidebar}
			/>
			<LayoutContent footer={<DefaultFooter />}>
				<Suspense fallback={<Loading />}>{renderRoutes()}</Suspense>
			</LayoutContent>
		</div>
	);
};

export default DefaultLayout;

import types from "./actionTypes";
import * as fileService from "../services/file-service";
import * as applicationService from "../../recruiter/Applications/services/applications";

// Dynamic Form Actions

export const fetchApplications = () => ({
	type: types.FETCH_APPLICATIONS,
	payload: applicationService.fetchApplications(),
});
export const fetchApplicationData = (applicationDataId) => ({
	type: types.FETCH_APPLICATION_DATA,
	payload: applicationService.fetchApplicationData(applicationDataId),
});

export const fetchApplicationTemplate = (applicationId) => ({
	type: types.FETCH_APPLICATION_TEMPLATE,
	payload: applicationService.fetchApplicationTemplate(applicationId),
});

export const updateFormData = (applicationDataId, data) => ({
	type: types.UPDATE_FORM_DATA,
	payload: applicationService.updateApplicationData(applicationDataId, data),
});

export const uploadFile = (applicationDataId, keys, file) => ({
	type: types.UPLOAD_FILE,
	payload: fileService.uploadFile(applicationDataId, keys, file),
});

export const deleteFile = (applicationDataId, keys) => ({
	type: types.DELETE_FILE,
	payload: fileService.deleteFile(applicationDataId, keys),
});

export const fetchFile = (applicationDataId, keys) => ({
	type: types.FETCH_FILE,
	payload: fileService.fetchFile(applicationDataId, keys),
});

export const saveFileBlob = (keys, blob) => ({
	type: types.SAVE_FILE_BLOB,
	keys,
	blob,
});

// current user activity

export const setTableData = (table, dynamicKey) => ({
	type: types.SET_TABLE_DATA,
	table,
	dynamicKey,
});

export const setPilotIds = (applicationIds) => ({
	type: types.SET_APPLICATION_IDS,
	applicationIds,
});

export const patchLocalApplicationData = (patchedApplicationData) => ({
	type: types.PATCH_LOCAL_APPLICATION_DATA,
	patchedApplicationData,
});

export const setPatchHistory = (patchHistory) => ({
	type: types.SET_PATCH_HISTORY,
	patchHistory,
});
export const updatePatchHistory = () => ({
	type: types.UPDATE_PATCH_HISTORY,
});

export const resetPatchHistory = () => ({
	type: types.RESET_PATCH_HISTORY,
});

export const rollbackPatchHistory = () => ({
	type: types.ROLLBACK_PATCH_HISTORY,
});

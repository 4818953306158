// Action type constants
const FETCH_INVITATIONS = "FETCH_INVITATIONS";
const CREATE_INVITATION = "CREATE_INVITATION";
const REMOVE_INVITATION = "REMOVE_INVITATION";
const RESEND_INVITATION = "RESEND_INVITATION";

const FILTER_INVITATIONS = "FILTER_INVITATIONS";
const CLEAR_FILTER = "CLEAR_FILTER";

export default {
	FETCH_INVITATIONS,
	CREATE_INVITATION,
	REMOVE_INVITATION,
	RESEND_INVITATION,
	FILTER_INVITATIONS,
	CLEAR_FILTER,
};

import _ from "lodash";
import * as parseUtils from "../../utils/parseUtils.js";
import dateFormat from "dateformat";

/* Actions */
const ACTIONS = {
	COUPON_PROCESSING: "COUPON_PROCESSING",
	COUPON_SUCCESS: "COUPON_SUCCESS",
	COUPON_ERROR: "COUPON_ERROR",
	COUPON_CLEAR: "COUPON_CLEAR",
	COUPONS_LIST_SUCCESS: "COUPONS_LIST_SUCCESS",
	COUPONS_LIST_ERROR: "COUPONS_LIST_ERROR",
	COUPON_DETAILS_SUCCESS: "COUPON_DETAILS_SUCCESS",
	COUPON_DETAILS_ERROR: "COUPON_DETAILS_ERROR",
	COUPON_UPDATE_SUCCESS: "COUPON_UPDATE_SUCCESS",
	CLEAR_COUPON_DETAILS: "CLEAR_COUPON_DETAILS",
	COUPON_UPDATE_ERROR: "COUPON_UPDATE_ERROR",
	CHECK_COUPONS_PROCESSING: "CHECK_COUPONS_PROCESSING",
	CHECK_COUPONS_RESULTS: "CHECK_COUPONS_RESULTS",
	CHECK_COUPONS_ERROR: "CHECK_COUPONS_ERROR",
};

const action = (type, payload) => ({
	type,
	payload,
});

export const createProductCoupon = coupon => dispatch => {
	const {
		max_uses,
		max_account_uses,
		count,
		expiration,
		product_ids: productIds,
		mailing_list_ids: mailingListIds,
		restrictions,
	} = coupon;

	let expirationDateFormatted = null;

	if (expiration !== undefined) {
		expirationDateFormatted = expiration && dateFormat(expiration, "UTC:yyyy-mm-dd'T'HH:MM:00.000'Z'");
	}

	const product_ids
		= productIds
		&& productIds.map(product => {
			return product.product_id;
		});
	const mailing_list_ids
		= mailingListIds
		&& mailingListIds.map(newsletter => {
			return newsletter.ListID;
		});

	dispatch(action(ACTIONS.COUPON_PROCESSING));
	const params = {
		...coupon,
		max_uses: _.toInteger(max_uses) || undefined,
		max_account_uses: _.toInteger(max_account_uses),
		count: _.toInteger(count),
		expirationDateFormatted,
		product_ids,
		mailing_list_ids,
		restrictions: restrictions ? restrictions.reduce((acc, key) => ({ ...acc, [key]: true }), {}) : undefined,
	};
	parseUtils.createProductCoupon(params, (err, result) => {
		if (err) {
			dispatch(action(ACTIONS.COUPON_ERROR, err));
		}
		else {
			dispatch(action(ACTIONS.COUPON_SUCCESS, result));
		}
	});
};

export const getCouponsList = () => async dispatch => {
	dispatch(action(ACTIONS.COUPON_PROCESSING));
	await parseUtils.getCouponsList((err, result) => {
		if (err) {
			dispatch(action(ACTIONS.COUPONS_LIST_ERROR, err));
		}
		else {
			dispatch(action(ACTIONS.COUPONS_LIST_SUCCESS, result));
		}
	});
};
export const couponDetails = objectId => dispatch => {
	dispatch(action(ACTIONS.COUPON_PROCESSING));
	parseUtils.couponDetails(objectId, (err, result) => {
		if (err) {
			dispatch(action(ACTIONS.COUPON_DETAILS_ERROR, err));
		}
		else {
			dispatch(action(ACTIONS.COUPON_DETAILS_SUCCESS, result));
		}
	});
};
export const updateCoupon = values => dispatch => {
	let newValues = { ...values };

	if (values.max_uses !== undefined) {
		const max_uses = values.max_uses ? _.toInteger(values.max_uses) : null;
		newValues = { ...newValues, max_uses };
	}

	if (values.expiration !== undefined) {
		const expirationDateFormatted = values.expiration
			? dateFormat(values.expiration, "UTC:yyyy-mm-dd'T'HH:MM:00.000'Z'")
			: null;
		newValues = { ...newValues, expiration: expirationDateFormatted };
	}

	if (values.max_account_uses) {
		const max_account_uses = _.toInteger(values.max_account_uses);
		newValues = { ...newValues, max_account_uses };
	}

	if (values.product_ids) {
		const product_ids
			= Array.isArray(values.product_ids)
			&& values.product_ids.map(product => {
				return product.product_id;
			});
		newValues = { ...newValues, product_ids };
	}

	if (values.mailing_list_ids !== undefined) {
		const mailing_list_ids = Array.isArray(values.mailing_list_ids)
			? values.mailing_list_ids.map(newsletter => {
				return newsletter.ListID;
			  })
			: null;
		newValues = { ...newValues, mailing_list_ids };
	}

	if (values.restrictions) {
		newValues = { ...newValues, restrictions: values.restrictions.reduce((acc, key) => ({ ...acc, [key]: true }), {}) };
	}
	
	dispatch(action(ACTIONS.COUPON_PROCESSING));
	parseUtils.updateCoupon(newValues, (err, result) => {
		if (err) {
			dispatch(action(ACTIONS.COUPON_UPDATE_ERROR, err));
		}
		else {
			dispatch(action(ACTIONS.COUPON_UPDATE_SUCCESS, result));
		}
	});
};

export const clearCouponDetails = () => dispatch => {
	dispatch(action(ACTIONS.CLEAR_COUPON_DETAILS));
};

/* Reducer */
const initialState = {
	processing: false,
	error: undefined,
	warning: undefined,
	result: undefined,
};

export const checkCoupons = data => dispatch => {
	dispatch(action(ACTIONS.CHECK_COUPONS_PROCESSING));
	const separated = data.couponCodes.replace(/(,|;)\s?/gmu, "@");
	const clean = separated.replace(/(\r\n|\n|\r)|(['"]+)/gmu, "");
	let couponCodes = clean.split("@");
	couponCodes = couponCodes.map(coupon => coupon.toUpperCase());
	parseUtils.checkCoupons(couponCodes, (err, result) => {
		if (err) {
			dispatch(action(ACTIONS.CHECK_COUPONS_ERROR, err));
		}
		else {
			dispatch(action(ACTIONS.CHECK_COUPONS_RESULTS, result));
		}
	});
};

// eslint-disable-next-line no-shadow
const couponReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.COUPON_PROCESSING:
			return {
				...state,
				error: undefined,
				processing: true,
				couponUpdated: false,
				couponUpdate_error: undefined,
				checkCouponsProcessing: false,
				checkCouponsError: undefined,
				checkCouponsResults: undefined,
			};

		case ACTIONS.COUPON_ERROR:
			return {
				...state,
				result: undefined,
				processing: false,
				error: action.payload,
			};

		case ACTIONS.COUPON_SUCCESS:
			return {
				...state,
				error: undefined,
				warning: action.payload.error,
				processing: false,
				result: action.payload,
			};

		case ACTIONS.COUPONS_LIST_SUCCESS:
			return {
				...state,
				couponsList: action.payload,
				processing: false,
			};
		case ACTIONS.COUPONS_LIST_ERROR:
			return {
				...state,
				couponsList_error: action.payload,
				processing: false,
			};
		case ACTIONS.COUPON_DETAILS_SUCCESS:
			return {
				...state,
				couponDetails_success: action.payload,
				processing: false,
			};
		case ACTIONS.COUPON_DETAILS_ERROR:
			return {
				...state,
				couponDetails_error: action.payload,
				processing: false,
			};
		case ACTIONS.CLEAR_COUPON_DETAILS:
			return {
				...state,
				couponDetails_success: undefined,
				processing: false,
			};
		case ACTIONS.COUPON_UPDATE_SUCCESS:
			return {
				...state,
				couponUpdated: action.payload,
				processing: false,
			};
		case ACTIONS.COUPON_UPDATE_ERROR:
			return {
				...state,
				couponUpdate_error: action.payload,
				processing: false,
			};
		case ACTIONS.COUPON_CLEAR:
			return { ...initialState };
		case ACTIONS.CHECK_COUPONS_PROCESSING:
			return {
				...state,
				checkCouponsProcessing: true,
			};
		case ACTIONS.CHECK_COUPONS_RESULTS:
			return {
				...state,
				checkCouponsProcessing: false,
				checkCouponsResults: action.payload,
				checkCouponsError: undefined,
			};
		case ACTIONS.CHECK_COUPONS_ERROR:
			return {
				...state,
				checkCouponsProcessing: false,
				checkCouponsResults: undefined,
				checkCouponsError: action.payload,
			};
		default:
			return state;
	}
};

export default couponReducer;

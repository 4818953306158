import * as actions from "./actions";
import * as dynamicFormActions from "../../../common/redux/actions";

export const documentOperation = (operation, documentId, rejectReason) => async (dispatch, getState) => {
	const { pilotApplicationIds: { applicationId, applicationDataId } } = getState().vitae.dynamicFormReducer;
	await dispatch(actions.reviewDocument(operation, applicationId, documentId, rejectReason));
	return dispatch(dynamicFormActions.fetchApplicationData(applicationDataId));
};

export default {
	...actions,
};

import * as parseUtils from "../../utils/parseUtils.js";
import { integrateKnownUser } from "./integrate";
import { queryClient } from "../../utils/reactQueryClient";

/* WEB-1120 - suppress pilot profile
import { realmApp } from "../../lib/realm/domain/shared/app";
*/

/* Actions */
export const ACTIONS = {
	USER_PROCESSING: "USER_PROCESSING",
	RECEIVE_USER: "RECEIVE_USER",
	USER_LOGOUT: "USER_LOGOUT",
	USER_REFRESH: "USER_REFRESH",
	USER_ERROR: "USER_ERROR",
	PASSWORD_RESET_SENT: "PASSWORD_RESET_SENT",
	CLEAR_AUTH_MESSAGES: "PASSWORD_RESET_CLEAR_MESSAGE",
	PASSWORD_RESET_ERROR: "PASSWORD_RESET_ERROR",
	POPULATE_USER_INITIAL_STATE: "POPULATE_USER_INITIAL_STATE",
	HYDRATE_USER: "HYDRATE_USER",
	HIDE_WELCOME_BOX: "HIDE_WELCOME_BOX",
};

const action = (type, payload) => ({
	type,
	payload,
});

export const login = (email, password) => async dispatch => {
	// dispatch notification informing of login start
	dispatch(action(ACTIONS.USER_PROCESSING));
	dispatch(integrateKnownUser());

	try {
		const userData = await parseUtils.login(email, password);
		const isAdmin = await parseUtils.checkAdminAccess();

		// dispatch RECEIVE_USER actions after both permission checks are done (WAA ADMIN & VITAE)
		dispatch(action(ACTIONS.RECEIVE_USER, { ...userData, isAdmin }));
	}
	catch (error) {
		const { message } = parseUtils.handleAuthError(error);
		dispatch(action(ACTIONS.USER_ERROR, message));
	}
};

export const signup = (email, password) => async dispatch => {
	// dispatch notification informing of login start
	dispatch(action(ACTIONS.USER_PROCESSING));
	dispatch(integrateKnownUser());

	try {
		const userData = await parseUtils.signup(email, password);
		dispatch(action(ACTIONS.RECEIVE_USER, userData));
	}
	catch (error) {
		const { message } = parseUtils.handleAuthError(error);
		dispatch(action(ACTIONS.USER_ERROR, message));
	}
};

export const logout = callback => async dispatch => {
	dispatch(action(ACTIONS.USER_LOGOUT));
	parseUtils.logout().finally(() => callback && callback(true));

	/* WEB-1120 - suppress pilot profile
	if (realmApp.currentUser) {
		await realmApp.currentUser.logOut();
	}
	*/
	await queryClient.resetQueries();
};

export const resetPassword = email => async dispatch => {
	// dispatch notification informing of reset password
	dispatch(action(ACTIONS.USER_PROCESSING));

	try {
		await parseUtils.resetPassword(email);
		const payload
			= "If an account exists with this email address, you will be sent an email with instructions to reset your password.";
		dispatch(action(ACTIONS.PASSWORD_RESET_SENT, payload));
	}
	catch (error) {
		const { message } = parseUtils.handleParseError(error);
		dispatch(action(ACTIONS.PASSWORD_RESET_ERROR, message));
	}
};

/** Hydrates Parse User from sessionToken stored in redux persistent storage. */
export const hydrateUser = sessionToken => async dispatch => {
	if (sessionToken) {
		try {
			await parseUtils.hydrateUser(sessionToken);
		}
		catch (error) {
			const { message } = parseUtils.handleAuthError(error);
			dispatch(action(ACTIONS.USER_ERROR, message));
			dispatch(logout());
		}
	}
};

export const populateUserInitialState = () => dispatch => {
	dispatch(action(ACTIONS.POPULATE_USER_INITIAL_STATE));
};

export const hideWelcomeBox = () => dispatch => {
	dispatch(action(ACTIONS.HIDE_WELCOME_BOX));
};

/**
 * clears login / signup / password reset error messages
 */
export const clearAuthMessages = () => dispatch => {
	dispatch(action(ACTIONS.CLEAR_AUTH_MESSAGES));
};

/* Reducer */
const initialState = {
	userIdentifier: undefined,
	userEmail: undefined,
	userEmailVerified: undefined,
	userAdmin: false,
	sessionToken: undefined,
	processing: false,
	error: undefined,
	resetError: undefined,
	resetMessage: undefined,
	hideWelcomeBox: false,
};

// eslint-disable-next-line no-shadow
const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.CLEAR_AUTH_MESSAGES: {
			return {
				...state,
				resetError: undefined,
				resetMessage: undefined,
				error: undefined,
			};
		}

		case ACTIONS.USER_PROCESSING:
			return {
				...state,
				resetError: undefined,
				resetMessage: undefined,
				error: undefined,
				processing: true,
			};

		case ACTIONS.USER_ERROR:
			return {
				...state,
				error: action.payload,
				processing: false,
			};

		case ACTIONS.RECEIVE_USER: {
			return {
				...state,
				error: undefined,
				processing: false,
				...action.payload,
			};
		}

		case ACTIONS.USER_LOGOUT:
			// clear all variables in the store after logout
			return { ...initialState };

		case ACTIONS.PASSWORD_RESET_ERROR: {
			return {
				...state,
				resetError: action.payload,
				resetMessage: undefined,
				processing: false,
			};
		}

		case ACTIONS.PASSWORD_RESET_SENT: {
			return {
				...state,
				resetError: undefined,
				resetMessage: action.payload,
				processing: false,
			};
		}

		case ACTIONS.POPULATE_USER_INITIAL_STATE: {
			const defaultUserRep = parseUtils.currentUserRepresentation();
			return { ...initialState, ...defaultUserRep };
		}

		case ACTIONS.HIDE_WELCOME_BOX: {
			return {
				...state,
				hideWelcomeBox: true,
			};
		}

		default:
			return state;
	}
};

export default userReducer;

import React from "react";
import { Box } from "@material-ui/core";

import AccountDropdown from "./AccountDropdown";

/* WEB-1120 - suppress pilot profile
import { Box, Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { JobOffersHeaderStatus } from "./JobOffers";
import { LTConnectionsHeaderStatus } from "./LTConnection";
import { IS_JOB_OFFER_AVAILABILITY_ENABLED } from "../../../utils/constants";
*/

/* WEB-1120 - suppress pilot profile
const useStyles = makeStyles((theme: Theme) => ({
	// styles applied to the divider element
	divider: {
		margin: theme.spacing(0, 2),
		height: 14,
		alignSelf: "center",
	},
}));
*/

export type HeaderRightContentProps = {
	hideAvatar?: boolean;
};

/** Contains all components that need to be displayed at the right side of the header */
export const HeaderRightContent = (props: HeaderRightContentProps) => {
	/* WEB-1120 - suppress pilot profile
	const classes = useStyles();
	const divider = <Divider flexItem className={classes.divider} orientation="vertical" variant="middle" />;
	const isMobile = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down("xs"));
	*/

	return (
		<Box display="flex" alignItems="center">
			{/* WEB-1120 - suppress pilot profile
			{!isMobile && (
				<>
					<LTConnectionsHeaderStatus />
					{divider}
					{IS_JOB_OFFER_AVAILABILITY_ENABLED && (
						<>
							<JobOffersHeaderStatus />
							{divider}
						</>
					)}
				</>
			)}
			*/}
			{!props.hideAvatar && <AccountDropdown />}
		</Box>
	);
};

import _ from "lodash";

//================================================================================
// HELPERS FOR REDUX FORM
// Usage: use these functions in the "format" field of Redux Field Component
// Example:
// <Field name="test" type="text" component={TextInput} format={extractAlphanum}/>
//================================================================================
const extract = (str, pattern) => (str.match(pattern) || []).pop() || "";

// Allow only Alphanumerical characters (Optional upperCase output)
export const extractAlphanum = (str, upperCase) => {
	if (!_.isEmpty(str)) {
		const newStr = extract(str, "[0-9a-zA-Z-]+");
		return upperCase ? newStr.toUpperCase() : newStr;
	}
	return "";
};

// Allow only valid email characters
export const extractValidEmailCharacters = (str, lowerCase) => {
	if (!_.isEmpty(str)) {
		const newStr = extract(str, "[0-9a-zA-Z.@!#$%&'*+-/=?^_`{|}~]+");
		return lowerCase ? newStr.toLowerCase() : newStr;
	}
	return "";
};

// Limit Length of user input
export const limitLength = (str, length) => {
	if (!_.isEmpty(str)) {
		return str.substring(0, length);
	}
	return "";
};

export const removeWhitespace = str => {
	if (!_.isEmpty(str)) {
		return str.replace(/^\s+|\s+$/gu, "");
	}
	return "";
};

/**
 * @date The date to format. When empty, defaults to now.
 * @return Returns a string formatted to be safe for the file system.
 * */
export const fileSafeDateISOString = (date = new Date()) => {
	return date.toISOString().replace(/:/gu, "-");
};

export const validateEmail = email => {
	const reg = new RegExp(
		"[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?",
		"u",
	);
	return reg.test(email || "");
};
export const validatePassword = password => {
	return password && password.length >= 10;
};

export const validateUrl = url => {
	const reg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gu;
	return reg.test(url || "");
};

//================================================================================
// JSON to CSV Conversion & Download Helpers
//================================================================================

export const convertArrayOfObjectsToCSV = (data = null, columnDelimiter = ",", lineDelimiter = "\n") => {
	if (data === null || !data.length) {
		return null;
	}

	const keys = Object.keys(data[0]);

	let result = "";
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	data.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) {
				result += columnDelimiter;
			}

			result += item[key];
			ctr += 1;
		});
		result += lineDelimiter;
	});

	return result;
};

export const downloadCSV = (data, filename = "export.csv") => {
	let csv = convertArrayOfObjectsToCSV(data);

	if (!csv) {
		return false;
	}

	if (!csv.match(/^data:text\/csv/iu)) {
		csv = "data:text/csv;charset=utf-8," + csv;
	}

	downloadString(csv, filename);

	return true;
};

//================================================================================
// Convert & Download Array in TXT File
//================================================================================

export const downloadTXT = (data, filename = "generated.txt", linebreak = false) => {
	let text = data && data.toString();

	if (!text) {
		return false;
	}

	if (linebreak) {
		text = text.replace(/,/gu, "\n");
	}

	if (!text.match(/^data:text\/plain/iu)) {
		text = "data:text/plain;charset=utf-8," + text;
	}

	downloadString(text, filename);

	return true;
};

function downloadString(string, filename) {
	const data = encodeURI(string);

	const link = document.createElement("a");
	link.setAttribute("href", data);
	link.setAttribute("download", filename);
	document.body.appendChild(link); // add link to the DOM to fix download in Firefox
	link.click();
	link.remove(); // remove link for better performance
}

//================================================================================
// Mime Type Checker
//================================================================================

/**
 * @encoded Base64-encoded string that represents the file
 * @return Returns the Mime Type of the base64-encoded string. Returns an empty
 * string if the argument is not a string or not a Base64-encoded string
 * */
export const mimeTypeFromBase64 = encoded => {
	let result = "";

	if (typeof encoded !== "string") {
		return result;
	}

	// WEB-579 ESLint unicode regexp rule prevents Safari from detecting mime type.
	// eslint-disable-next-line require-unicode-regexp
	const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

	if (mime && mime.length) {
		result = mime[1];
	}

	return result;
};

/**
 * patch revalidate validator so we can pass in our own error message rather than
 * using the default error message provided by `revalidate`
 */
export const patchValidator = (validator, message) => (...args) => {
	// if the original `validator()` returns a validation error message, the field is invalid, so return our custom message
	return validator(...args) && message;
};

export enum TouUserAcceptance {
	Valid = "valid",
	Invalid = "invalid",
}

export interface Tou {
	publish_date?: Date;
	content_uri?: string;
	user_acceptance: TouUserAcceptance;
	content?: string;
	content_url?: string;
}

import { Parse } from "parse";

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JAVASCRIPT_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL;

export const logout = () => {
	return Parse.User.logOut();
};

export function handleParseError(err) {
	switch (err.code) {
		case Parse.Error.INVALID_SESSION_TOKEN: {
			const user = Parse.User.current();
			if (user) {
				logout().finally(() => {
					window.location.replace("/login");
				});
			}
			return { code: 401, message: "Invalid Session Token." };
		}
		case Parse.Error.CONNECTION_FAILED:
			return { code: 100, message: "Unsuccessful connection to server." };
		case Parse.Error.OBJECT_NOT_FOUND:
			return { code: 101, message: "Object not found." };
		case Parse.Error.INVALID_JSON:
			return { code: 107, message: "The server returned an invalid response." };
		case Parse.Error.INTERNAL_SERVER_ERROR:
			return { code: 500, message: "Internal Server Error." };
		default: {
			let message = "An unknown error occurred.";
			let code = 520;
			if (err.message) {
				if (err.message.cloud_error_description) {
					message = err.message.cloud_error_description;
				}
				else {
					message = err.message;
				}
				if (err.message.cloud_error_code) {
					code = err.message.cloud_error_code;
				}
			}
			return { code, message };
		}
	}
}

/* Converts a Parse.User into a representation that can be used by other classes without the need for Parse. */
function userRepresentation(user) {
	if (!user) {
		return undefined;
	}
	return {
		userIdentifier: user.id,
		userEmail: user.getEmail(),
		userEmailVerified: user.get("emailVerified"),
		sessionToken: user.getSessionToken(),
	};
}

function loginErrorCodeMessage(code) {
	switch (code) {
		case 100:
			return "Unsuccessful connection to server.";
		case 101:
			return "Login failed. Please check your info and try again.";
		case 142:
			return "Password cannot contain your username.";
		case 202:
		case 203:
			return "An account with this email already exists.";
		default:
			return "An unknown error occurred";
	}
}

export const resetPassword = email => Parse.User.requestPasswordReset(email);

export const currentUser = () => userRepresentation(Parse.User.current());

const handleUserSuccess = user => {
	const userRep = userRepresentation(user);
	return userRep;
};

export const handleAuthError = error => {
	const code = error.code || 520;
	const message = loginErrorCodeMessage(code);
	return { code, message };
};

/*
 * Attempt to login a user with the given username and password. Upon completion, calls back with any error and user.
 */
export const login = async (username, password) => Parse.User.logIn(username, password).then(handleUserSuccess);

export const signup = async (username, password) => {
	const lc = username.toLowerCase();
	return Parse.User.signUp(lc, password, { email: lc }).then(handleUserSuccess);
};

export const hydrateUser = async sessionToken => Parse.User.become(sessionToken).then(handleUserSuccess);

export const ssoAuth = async (url) => Parse.Cloud.run("ssoAuth", { url });

export const servicesApiAuth = async () => {
	const payload = { type: "jwt", service: "svcs" };
	return Parse.Cloud.run("ssoAuth", payload);
};

export const vitaeAuth = async () => {
	const payload = { type: "jwt", service: "vitae.auth" };
	return Parse.Cloud.run("ssoAuth", payload).catch(err => handleParseError(err));
};

export const realmAuth = async () => {
	const payload = { type: "jwt", service: "realm" };
	return Parse.Cloud.run("ssoAuth", payload).catch(err => handleParseError(err));
};

export const redeem = (code, callback) => {
	Parse.Cloud.run("redeem", { code }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};

export const checkAdminAccess = async () => {
	return Parse.Cloud.run("adminAccess", null)
		.then(() => true)
		.catch(err => false);
};

export const currentUserRepresentation = () => {
	const user = Parse.User.current();
	if (user) {
		const rep = userRepresentation(user);
		return rep;
	}

	return undefined;
};

export const createEnterpriseCoupon = (code, name, description, callback) => {
	Parse.Cloud.run("createEnterpriseCoupon", { code, name, description }).then(
		() => callback && callback(null, true),
		err => callback && callback(handleParseError(err), false),
	);
};

export const resetDeviceActivations = (customer_email, callback) => {
	Parse.Cloud.run("resetDeviceActivations", { customer_email }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const purchaseHistory = (customer_email, callback) => {
	Parse.Cloud.run("purchaseHistory", { customer_email }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const requestValidationEmail = (user, callback) => {
	Parse.Cloud.run("requestValidationEmail", { user }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const assignableProducts = callback => {
	Parse.Cloud.run("assignableProducts").then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const assignProduct = (values, callback) => {
	const { customer_email, product_id } = values;
	Parse.Cloud.run("assignProduct", { customer_email, product_id }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const changeEmail = (values, callback) => {
	const { customer_email, customer_new_email } = values;
	Parse.Cloud.run("changeEmail", { customer_email, customer_new_email }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const createProductCoupon = (params, callback) => {
	Parse.Cloud.run("createProductCoupon", params).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const fetchNewsletterLists = callback => {
	Parse.Cloud.run("fetchNewsletterLists").then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const getCouponsList = callback => {
	Parse.Cloud.run("getCouponsList").then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const couponDetails = (objectId, callback) => {
	Parse.Cloud.run("couponDetails", { objectId }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const couponUsers = (objectId, callback) => {
	Parse.Cloud.run("couponUsers", { objectId }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const updateCoupon = (values, callback) => {
	Parse.Cloud.run("updateCoupon", { values }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const checkCoupons = (couponCodes, callback) => {
	Parse.Cloud.run("checkCoupons", { couponCodes }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const uploadBrand = (code, name, url, logo, callback) => {
	Parse.Cloud.run("uploadBrand", { code, name, url, logo }).then(
		results => callback && callback(null, results),
		err => callback && callback(handleParseError(err), false),
	);
};
export const retrieveGlacierFile = (url, callback) => {
	Parse.Cloud.run("retrieveGlacierFile", { url }).then(
		payload => callback && callback(null, payload),
		err => callback && callback(handleParseError(err), false),
	);
};
export const getUserAccountDetails = (customer_email, callback) => {
	Parse.Cloud.run("getUserAccountDetails", { customer_email }).then(
		payload => callback && callback(null, payload),
		err => callback && callback(handleParseError(err), false),
	);
};
export const persistShareLink = (values, callback) => {
	Parse.Cloud.run("persistShareLink", values).then(
		payload => callback && callback(null, payload),
		err => callback && callback(handleParseError(err), false),
	);
};
export const availableOffers = (customer_email, callback) => {
	Parse.Cloud.run("availableOffers", { customer_email }).then(
		payload => callback && callback(null, payload),
		err => callback && callback(handleParseError(err), false),
	);
};
export const assignOffer = (values, callback) => {
	const { customer_email, offer } = values;
	Parse.Cloud.run("assignOffer", { customer_email, offer: { identifier: offer.identifier } }).then(
		payload => callback && callback(null, payload),
		err => callback && callback(handleParseError(err), false),
	);
};
export const existingOffers = (customer_email, callback) => {
	Parse.Cloud.run("existingOffers", { customer_email }).then(
		payload => callback && callback(null, payload),
		err => callback && callback(handleParseError(err), false),
	);
};

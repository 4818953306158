import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import BrandFragment from "../../common/BrandFragment";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		minHeight: "calc(100vh - 230px)",
	},
	brandLogo: {
		marginBottom: theme.spacing(3),
		"& img": {
			maxHeight: 200,
			width: "auto",
		},
	},
}));
export interface BrandedContentProps {
	page?: string;
	section?: string;
}

/** Displays content with marketing brand (ie: USAF) on top */
export const BrandedContent: React.FC<BrandedContentProps> = ({ children, page = "redeemed", section = "header" }) => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Box className={classes.brandLogo} textAlign="center" data-cy="brand">
				<BrandFragment page={page} section={section} />
			</Box>
			{children}
		</Box>
	);
};

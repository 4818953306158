import { Button, LoadingButton, Modal, ModalActions, ModalProps } from "@coradine/web-ui";
import { Box, BoxProps, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { Small, Text } from "../../../../common/typography";
import { formatDate } from "../../../../utils/dateUtils";

const useStyles = makeStyles((theme: Theme) => ({
	badge: {
		border: `1px solid ${theme.palette.warning.main}`,
		borderRadius: 4,
		padding: "2px 4px",
		display: "inline-block",
	},
	date: {
		marginLeft: 8,
	},

	declineModalActions: {
		justifyContent: "flex-end",
		margin: theme.spacing(3, 3, 3, 3),
	},
}));

interface UpdateBadgeProps extends BoxProps {
	updatedAt: Date;
}

const UpdatedBadge = ({ updatedAt, ...props }: UpdateBadgeProps) => {
	const classes = useStyles();
	return (
		<Box display="flex" alignItems="center" mb="10px" {...props}>
			<Small
				fontWeight="fontWeightMedium"
				color="warning.main"
				className={classes.badge}
			>
				UPDATED
			</Small>
			<Text
				color="grey.400"
				fontWeight="fontWeightMedium"
				className={classes.date}
			>
				{formatDate(updatedAt)}
			</Text>
		</Box>
	);
};

interface DeclineToUModalProps extends ModalProps {
	handleOk: () => void;
	handleCancel: () => void;
}

export const DeclineToUModal: React.FC<DeclineToUModalProps> = (props) => {
	const { handleOk, handleCancel, ...restProps } = props;
	const classes = useStyles();
	return (
		<Modal
			title="Decline Terms of Use?"
			id="confirm-decline-tou"
			maxWidth="sm"
			actions={
				<ModalActions classes={{ root: classes.declineModalActions }}>
					<Button variant="outlined" onClick={handleCancel}>
						Cancel
					</Button>
					<Button variant="contained" color="error" onClick={handleOk}>
						Decline
					</Button>
				</ModalActions>
			}
			{...restProps}
		>
			Acceptance is required for continued access to your Coradine Account and LogTen Applications.
		</Modal>
	);
};

export interface ToUModalProps extends ModalProps {
	handleAccept: () => void;
	handleDecline: () => void;
	updatedAt: Date;
	content: string;
	isAcceptingTou?: boolean;
}

export const ToUModal: React.FC<ToUModalProps> = ({
	handleAccept,
	handleDecline,
	open,
	updatedAt,
	title = "Terms of Use",
	subtitle = "Our Terms of Use have changed. Please read and agree to the updated terms in order to continue.",
	content = "<div />",
	isAcceptingTou = false,
	...props
}) => {
	const [declineModalOpen, setDeclineModalOpen] = useState(false);
	const toggleDeclineModal = () => {
		setDeclineModalOpen(!declineModalOpen);
	};

	return (
		<>
			<DeclineToUModal
				handleOk={handleDecline}
				handleCancel={toggleDeclineModal}
				open={declineModalOpen}
			/>

			<Modal
				title={title}
				subtitle={subtitle}
				open={open}
				maxWidth="md"
				actions={
					<ModalActions>
						<Button className="declineButton" onClick={toggleDeclineModal}>
							Decline
						</Button>
						<LoadingButton loading={isAcceptingTou} variant="contained" onClick={handleAccept}>
							Agree to the Terms of Use
						</LoadingButton>
					</ModalActions>
				}
				{...props}
			>
				<div style={{ padding: "0 30px" }}>
					<UpdatedBadge updatedAt={updatedAt}/>
					<div id="terms-content" dangerouslySetInnerHTML={{ __html: content }}/>
				</div>
			</Modal>
		</>
	);
};

import axios from "axios";
import { store } from "../../Root";
import actions from "../auth/redux/operations";

export const buildAuthorizationHeader = (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
	};
	return headers;
};

/**
 * A small utility that allows to make an API Request to Vitae server using axios library. It returns the data or catches any request errors.
 * By default, this function will retry 3 times when a request is failed due to unauthorized access (JWT Expiration)
 *
 * @param  {String} endpoint Vitae API Endpoint excluding the base url & without leading slash (example: 'invitations/:id/accept')
 * @param  {('GET'|'POST'|'PATCH'|'PUT'|'DELETE'|'REQUEST'|'HEAD')} method API Request Method
 * @param  {Object} body API Request body
 * @param  {Object} headers Any Additional Headers
 * @param  {Object} reqExtra Extra variables to be returned with result (ie: delete item ID)
 *
 * @returns {Promise} Promise object that contains the response.data and reqExtra (when passed as argument)
 */

// eslint-disable-next-line max-params
const vitaeRequest = async (endpoint, method, body = {}, customHeaders, responseType = "json", retries = 3) => {
	const { authToken } = store().getState().vitae.authReducer;
	const baseUrl = process.env.REACT_APP_VITAE_API_URL;

	const url = `${baseUrl}/${endpoint}`;
	const headers = {
		"Content-Type": "application/json",
		Authorization: authToken ? `Bearer ${authToken}` : undefined,
		...customHeaders,
	};
	
	try {
		const result = await axios({ method, url, data: body, headers, responseType });
		return result.data;
	}
	catch (error) {
		const { response } = error;
	
		// If unauthorized (401), try to re-authenticate
		if (response && response.status === 401) {
			if (retries > 0) {
				await store().dispatch(actions.refreshUser());
				return vitaeRequest(endpoint, method, body, customHeaders, responseType, retries - 1);
			}

			// If still unable to refresh user's JWT, logout user
			return store().dispatch({ type: "USER_LOGOUT" });
		}
	
		// throw complete error to allow error handling elsewhere
		throw error;
	}
};

export default vitaeRequest;

import _ from "lodash";
import vitaeRequest, { buildAuthorizationHeader } from "../../utils/vitaeRequest";
import * as actions from "./actions";
import * as parseUtils from "../../../utils/parseUtils";

/**
 * Authenticate with Vitae as an individual
 * @param  {string} authToken
 */
const authenticateIndividual = (authToken) => async (dispatch) => {
	dispatch(actions.authRequest());
	
	return vitaeRequest("auth", "GET", null, buildAuthorizationHeader(authToken)).then(response => {
		const individualToken = response.token;
		dispatch(actions.saveToken(individualToken, "individual"));
	}).catch(error => dispatch(actions.setAuthError(error)));
};

/**
 * Returns first membership stored in redux
 */
const getFirstMembershipId = (memberships) => {
	if (memberships) {
		const membershipsArray = Object.values(memberships);
		return _.get(membershipsArray, "[0].id");
	}
	return null;
};

/**
 * Authenticate with Vitae as member of an organization. If no membershipId provided
 * as argument, the function will use the first membership available.
 */
const authenticateOrganization = (targetMembershipId = null) => async (dispatch, getState) => {
	dispatch(actions.authRequest());
	const { individualToken, memberships } = getState().vitae.authReducer;

	let membershipId = targetMembershipId;

	if (!membershipId) {
		membershipId = getFirstMembershipId(memberships);
	}
	
	return membershipId && vitaeRequest(`auth?membership=${membershipId}`, "GET", null, buildAuthorizationHeader(individualToken)).then(response => {
		const organizationToken = response.token;
		dispatch(actions.saveToken(organizationToken, "organization", membershipId));
	}).catch(error => dispatch(actions.setAuthError(error)));
};

/**
 * Retrieves memberships linked to a specific Vitae individual user
 */
const retrieveMemberships = () => async (dispatch, getState) => {
	dispatch(actions.membershipsRequest());
	const { individualToken } = getState().vitae.authReducer;

	return vitaeRequest("memberships", "GET", null, buildAuthorizationHeader(individualToken)).then(response => {
		const memberships = response.data || [];
		dispatch(actions.saveMemberships(memberships));
	}).catch(error => dispatch(actions.setMembershipsError(error)));
};

/**
 * Re-authenticate the current individual Vitae user to update their permissions
 */
const refreshIndividualPermissions = () => async dispatch => {
	const authToken = await parseUtils.vitaeAuth().then(results => results.jwt).catch(err => actions.setAuthError(err));
	await dispatch(authenticateIndividual(authToken));
};

/**
 * Re-authenticate the current Vitae user (ie: login/refresh authToken)
 */
const refreshUser = () => async dispatch => {
	// Authenticate with Vitae as an individual
	await dispatch(refreshIndividualPermissions());

	// Retrieve memberships of current user
	await dispatch(retrieveMemberships());

	// Authenticate with first membership in Beta release
	await dispatch(authenticateOrganization());
};

export default {
	...actions,
	authenticateIndividual,
	authenticateOrganization,
	retrieveMemberships,
	refreshIndividualPermissions,
	refreshUser,
};

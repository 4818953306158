import * as services from "../services/applications";
import types from "./actionTypes";
import _ from "lodash";

export const reviewDocument = (operation, applicationId, documentId, rejectReason) => ({
	type: `${_.findKey(services.DOCUMENT_OPERATIONS, item => item.indexOf(operation) !== -1)}_APPLICATION_DOCUMENT`,
	payload: services.reviewDocument(operation, applicationId, documentId, rejectReason),
});

export const fetchApplicationTemplates = () => ({
	type: types.FETCH_APPLICATION_TEMPLATES,
	payload: services.fetchApplicationTemplates(),
});

export const fetchApplicationById = (applicationId) => ({
	type: types.FETCH_APPLICATION_BY_ID,
	payload: services.fetchApplicationById(applicationId),
});

export const fetchApplications = () => ({
	type: types.FETCH_APPLICATIONS,
	payload: services.fetchApplications(),
});

export const deleteApplication = (application) => ({
	type: types.DELETE_APPLICATION,
	application,
});

export const filterApplications = (filteredData) => ({
	type: types.FILTER_APPLICATIONS,
	filteredData,
});

export const clearFilter = () => ({
	type: types.CLEAR_FILTER,
});

import _ from "lodash";
import { applyPatch, createPatch } from "rfc6902";

import * as actions from "./actions";
import { fetchApplicationById } from "../../recruiter/Applications/redux/actions";

// Dynamic Form Operations

export const extractApplicationIds = (application) => async (dispatch) => {
	const applicationId = application.id;
	const applicationDataId = application.relationships.applicationData.data.id;
	const userId = application.relationships.user.data.id;
	const appTemplateId = application.relationships.applicationTemplate.data.id;
	const orgId = application.relationships.organization.data.id;

	const applicationIds = { applicationId, userId, appTemplateId, applicationDataId, orgId };

	await dispatch(actions.setPilotIds(applicationIds));

	return applicationIds;
};

export const fetchDynamicFormData = (application) => async (dispatch) => {
	const { applicationDataId, applicationId } = await dispatch(extractApplicationIds(application));
	dispatch(actions.fetchApplicationData(applicationDataId));
	dispatch(actions.fetchApplicationTemplate(applicationId));
};

export const fetchApplicantDynamicFormData = () => async (dispatch) => {
	// 1. Fetch all applications
	const result = await dispatch(actions.fetchApplications());

	// 2. Get 1st application
	const application = result.value.data[0];

	// 3. Fetch applicationData & applicationTemplate
	dispatch(fetchDynamicFormData(application));
};

export const fetchRecruiterDynamicFormData = (applicationId) => async (dispatch) => {
	// 1. Fetch application
	const result = await dispatch(fetchApplicationById(applicationId));
	const application = result.value.data;

	// 2. Fetch applicationData & applicationTemplate
	dispatch(fetchDynamicFormData(application));
};

// PATCH request to update DB with changes and GET latest data
export const updateFormData = () => async (dispatch, getState) => {
	const { pilotApplicationIds: { applicationDataId }, workingPatchHistory } = getState().vitae.dynamicFormReducer;

	dispatch(actions.updatePatchHistory());
	try {
		await dispatch(actions.updateFormData(applicationDataId, workingPatchHistory));
		dispatch(actions.resetPatchHistory());
	}
	catch (error) {
		console.error("Form data not saved!", error);
		return dispatch(actions.rollbackPatchHistory());
	}
	return dispatch(actions.fetchApplicationData(applicationDataId));
};

export const uploadFile = (file, keys) => (dispatch, getState) => {
	const { pilotApplicationIds: { applicationDataId } } = getState().vitae.dynamicFormReducer;
	return dispatch(actions.uploadFile(applicationDataId, file, keys));
};

export const FILE_OPERATIONS = {
	UPLOAD: "upload",
	DELETE: "delete",
	FETCH: "fetch",
};

export const fileOperation = (operation, keys, file = null) => async (dispatch, getState) => {
	const { pilotApplicationIds: { applicationDataId } } = getState().vitae.dynamicFormReducer;
	switch (operation) {
		case FILE_OPERATIONS.UPLOAD: {
			await dispatch(actions.uploadFile(applicationDataId, keys, file));
			return dispatch(actions.fetchApplicationData(applicationDataId));
		}
		case FILE_OPERATIONS.DELETE:
			return dispatch(actions.deleteFile(applicationDataId, keys));
		case FILE_OPERATIONS.FETCH:
			return dispatch(actions.fetchFile(applicationDataId, keys));
		default:
			throw new Error("Unknown File Operation");
	}
};

let timeout = null;

/**
 * This operation is executed on every change made to the dynamic form.
 *
 * It builds and tracks the form data changes locally and sends PATCH updates
 * to the server. The process consists of a few steps:
 *
 * 1. It creates a PATCH instructions array (diff) between local pilotDetails and last changes
 * 3. It incrementally tracks the PATCH instructions in redux (workingPatchHistory) after each change.
 * 3. It then updates the local version of the formData (pilotDetails) with those instructions.
 * 4. Finally, it sends the PATCH instructions to the server after some delay.
 * This last step is further broken down into 3 essential steps:
 *    4.a - It creates a static `pendingPatchHistory` derived from the `workingPatchHistory`
 *    and resets the `workingPatchHistory`, which can be updated while the client-server transaction is in progress.
 *    4.b - If the PATCH request is successful, it clears the `pendingPatchHistory`.
 *    4.c - It then downloads the latest dynamicForm data (GET) and applies the combined pending/workingPatchHistory (see reducer)
 *
 * @param {Array} keys array of parentKeys of the currently edited field
 * @param {Any} data new field value
 */
export const buildPilotDetails = (keys, data) => {
	return async (dispatch, getState) => {
		const { pilotDetails } = getState().vitae.dynamicFormReducer;
		const existingData = _.get(pilotDetails, keys);

		if (!_.isEqual(existingData, data)) {
			const updatedPilotDetails = _.cloneDeep(pilotDetails);
			
			if (data) {
				_.set(updatedPilotDetails, keys, data);
			}
			else {
				_.unset(updatedPilotDetails, keys);
			}

			// 1. Build PATCH instructions history between last updated local data and new data
			const newPatchInstructions = createPatch({ data: pilotDetails }, { data: updatedPilotDetails });
			
			// 2. Update workingPatchHistory with newPatchInstructions (incremental changes)
			dispatch(actions.setPatchHistory(newPatchInstructions));

			// 3. Apply PATCH instructions to the new data
			applyPatch(updatedPilotDetails, newPatchInstructions);

			// 4. Update local data
			dispatch(actions.patchLocalApplicationData(updatedPilotDetails));

			if (!timeout) {
				timeout = setTimeout(() => {
					timeout = null;
					return dispatch(updateFormData());
				}, 1000);
			}
		}

		return null;
	};
};

export default {
	...actions,
};

import vitaeRequest from "../../../utils/vitaeRequest";

export const DOCUMENT_OPERATIONS = {
	APPROVE: "approve",
	REJECT: "reject",
	UNAPPROVE: "unapprove",
	UNREJECT: "unreject",
};

/**
 * Recruiter service that allows actions on an application document
 * @param operation {String} - type of the action: (approve|unapprove|unreject)
 * @param applicationId {String} - ID of the application
 * @returns {Promise}
 */
export const reviewDocument = async (operation, applicationId, documentId, rejectReason) => {
	const data = operation === DOCUMENT_OPERATIONS.REJECT ? { data: { reason: rejectReason } } : null;
	return vitaeRequest(`applications/${applicationId}/documents/${documentId}/${operation}`, "POST", data);
};

export const fetchApplicationTemplate = async (applicationId) => vitaeRequest(`applications/${applicationId}/applicationTemplate`, "GET");

export const fetchApplicationTemplates = async () => vitaeRequest("applicationTemplates", "GET");

export const fetchApplications = async () => vitaeRequest("applications", "GET");

export const fetchApplicationData = async (applicationDataId) => vitaeRequest(`applicationData/${applicationDataId}`, "GET");

export const updateApplicationData = async (applicationDataId, data) => vitaeRequest(`applicationData/${applicationDataId}`, "PATCH", data);

export const fetchApplicationById = async (id) => {
	return vitaeRequest(`applications/${id}`, "GET");
};


import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import types from "./actionTypes";

export const initialState = {
	applications: {},
	templates: {},
	applicationsFiltered: [],
	isFilterActive: false,
};

const applicationReducer = createReducer(initialState, {
	[`${types.FETCH_APPLICATION_TEMPLATES}_FULFILLED`]: (state, action) => {
		const templates = action.payload.data;
		const normalizedTemplates = _.mapKeys(templates, "id");
		return { ...state, templates: normalizedTemplates };
	},
	[`${types.FETCH_APPLICATION_BY_ID}_FULFILLED`]: (state, action) => {
		const application = action.payload.data;
		state.applications[application.id] = application;
	},
	[`${types.FETCH_APPLICATIONS}_FULFILLED`]: (state, action) => {
		const applications = action.payload.data;
		const normalizedApplications = _.mapKeys(applications, "id");
		return { ...state, applications: normalizedApplications };
	},
	[types.DELETE_APPLICATION]: (state, action) => {
		delete state.applications[action.application.id];
	},
	[types.FILTER_APPLICATIONS]: (state, action) => {
		const filteredInvitations = action.filteredData;
		return { ...state, applicationsFiltered: filteredInvitations, isFilterActive: true };
	},
	[types.CLEAR_FILTER]: (state, action) => {
		const filteredInvitations = [];
		return { ...state, applicationsFiltered: filteredInvitations, isFilterActive: false };
	},
});

export default applicationReducer;

import axios from "axios";
import format from "string-template";

const ACTIONS = {
	BRAND_MANIFEST_LOADING: "BRAND_MANIFEST_LOADING",
	BRAND_MANIFEST_RECEIVE: "BRAND_MANIFEST_RECEIVE",
	BRAND_CONTENT_LOADING: "BRAND_CONTENT_LOADING",
	BRAND_CONTENT_RECEIVE: "BRAND_CONTENT_RECEIVE",
	BRAND_CONTENT_ERROR: "BRAND_CONTENT_ERROR",
};

const externalResourceRoot = process.env.REACT_APP_PUBLIC_URL || "/";

function fetchBrandManifest(brand, callback) {
	const url = externalResourceRoot + "s3/" + brand + "/manifest.json";
	axios
		.get(url)
		.then(response => {
			callback(null, response.data);
		})
		.catch(error => {
			console.log(error);
			callback(error, null);
		});
}

// TODO: Re-implement default local manifest support.
// const defaultLocalManifest = require('../../../public/rsrcs/default/manifest.json');
// const { template_map } = defaultLocalManifest || {};
// defaultLocalManifest.template_map = {...template_map, 'DYNAMIC_BASE_PATH': '/rsrcs/default/' };

export const fetchBrand = name => {
	return (dispatch, getState) => {
		const { brand } = getState();
		
		let updatedName = name;
		const existing = brand.name;

		// Lowercase this path since that is how they're saved on S3.
		if (name) {
			updatedName = name.toLowerCase();
			
			// Do not reload the same brand
			if (existing && updatedName === existing) {
				return;
			}
		}
		else {
			// Do not overwrite an existing brand with the default.
			if (existing) {
				return;
			}

			updatedName = "_default";
		}

		dispatch({ type: ACTIONS.BRAND_MANIFEST_LOADING, payload: { name: updatedName } });
		fetchBrandManifest(updatedName, (err, manifest) => {
			if (manifest) {
				const rootPath = externalResourceRoot + "s3/" + updatedName + "/";
				manifest.template_map.DYNAMIC_BASE_PATH = rootPath;
				dispatch({
					type: ACTIONS.BRAND_MANIFEST_RECEIVE,
					payload: { rootPath, name: updatedName, manifest },
				});
			}
			else if (updatedName === "_default") {
				// No manifest, and the attempted brand is 'default'
				dispatch({
					type: ACTIONS.BRAND_MANIFEST_RECEIVE,
					payload: {
						name: "default_local",

						// TODO: Re-implement default local manifest support.
						// manifest: defaultLocalManifest,
						// rootPath: defaultLocalManifest.template_map.DYNAMIC_BASE_PATH,
					},
				});
			}
			else {
				// Original manifest fetch failed, so attempt to fetch the default.
				dispatch(fetchBrand("_default"));
			}
		});
	};
};

export const brandHtmlTemplate = (page, section) => (dispatch, getState) => {
	// Do not refetch if we're fetching, already configured.
	const { brand } = getState();
	const { loadingContent, rootPath, manifest } = brand;

	const loadingPage = loadingContent[page] || {};
	const loadingSection = loadingPage[section];

	if (!loadingSection && manifest) {
		// Locate the best-match template in the manifest.
		const { templates, template_map } = manifest;
		const htmlPath = getHtmlFragmentPath(templates, page, section);

		// Skip fetching null template
		if (!htmlPath) {
			dispatch({
				type: ACTIONS.BRAND_CONTENT_RECEIVE,
				payload: { htmlRaw: null, page, section },
			});
			return;
		}

		dispatch({
			type: ACTIONS.BRAND_CONTENT_LOADING,
			payload: { page, section },
		});

		// Fetch the template from the url.
		axios
			.get(rootPath + htmlPath)
			.then(response => {
				// Replace keys and values in the template.
				const replacedHTML = format(response.data, template_map);
				dispatch({
					type: ACTIONS.BRAND_CONTENT_RECEIVE,
					payload: { htmlRaw: replacedHTML, page, section },
				});
			})
			.catch(err => {
				console.error(err);
				dispatch({
					type: ACTIONS.BRAND_CONTENT_ERROR,
					payload: { page, section },
				});
			});
	}
};

function getHtmlFragmentPath(templates, page, section) {
	const pageValue = templates[page] || {};
	let sectionValue = pageValue[section];

	if (!sectionValue) {
		const defaultTemplate = templates.default || {};
		sectionValue = defaultTemplate[section];
	}

	return sectionValue;
}

const initialState = {
	name: undefined,
	rootPath: undefined,
	manifest: undefined,
	loadingManifest: false,
	content: {},
	loadingContent: {},
};

const brandReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.BRAND_CONTENT_LOADING: {
			const { page, section } = action.payload;
			const loadingContent = updateNestedPageSectionStructure(state.loadingContent, page, section, true);

			return {
				...state,
				loadingContent,
			};
		}
		case ACTIONS.BRAND_CONTENT_RECEIVE:
		case ACTIONS.BRAND_CONTENT_ERROR: {
			const { htmlRaw = "<div></div>", page, section } = action.payload;
			const content = updateNestedPageSectionStructure(state.content, page, section, htmlRaw);
			const loadingContent = updateNestedPageSectionStructure(state.loadingContent, page, section, false);

			return {
				...state,
				loadingContent,
				content,
			};
		}
		case ACTIONS.BRAND_MANIFEST_RECEIVE: {
			const currentName = state.name;
			const { name, manifest, rootPath } = action.payload;

			// Return the same state if the currentName differs from the receiving manifest name. The name is set when initiating a manifest fetch, and back-to-back fetches could cause a race condition. Ensuring the same name causes the most recent fetch request to win.
			if (currentName !== name) {
				return { ...state };
			}

			return {
				...state,
				manifest,
				rootPath,
				loadingManifest: false,
			};
		}

		case ACTIONS.BRAND_MANIFEST_LOADING:
			return {
				...initialState,
				name: action.payload.name,
				loadingManifest: true,
			};

		default:
			return state;
	}
};

// Convenience to copy all changes in the nested page/section structures used here.
function updateNestedPageSectionStructure(initial = {}, page, section, value) {
	const initialCopy = { ...initial };
	const pageCopy = { ...page };
	pageCopy[section] = value;
	initialCopy[page] = pageCopy;
	return initialCopy;
}

export default brandReducer;

import React from "react";
import flattenDeep from "lodash/flattenDeep";
import { ROLES } from "./utils/authority";

// WebAdminApp
const Account = React.lazy(() => import("./WebAdminApp/pages/Account"));
const Coupons = React.lazy(() => import("./WebAdminApp/Coupons"));
const Redeemed = React.lazy(() => import("./WebAdminApp/Redeemed"));
const CouponDetailed = React.lazy(() => import("./WebAdminApp/Coupons/CouponDetailed"));
const CouponUsers = React.lazy(() => import("./WebAdminApp/Coupons/CouponUsers"));
const Redeem = React.lazy(() => import("./WebAdminApp/Redeem"));
const AlreadyRedeemed = React.lazy(() => import("./WebAdminApp/Redeemed/AlreadyRedeemed"));
const FileTools = React.lazy(() => import("./WebAdminApp/FileTools"));
const JediBasicTools = React.lazy(() => import("./WebAdminApp/JediBasicTools"));
const PersistentLinks = React.lazy(() => import("./WebAdminApp/PersistentLinks"));
const LogbookTools = React.lazy(() => import("./WebAdminApp/LogbookTools"));

// End: WebAdminApp

// Vitae
const PilotProfile = React.lazy(() => import("./Vitae/pages/PilotProfile"));

// TODO: VIT-893 - PilotSearch work hasn't been completed yet. When the page will be available, uncomment the following line.
// const PilotSearch = React.lazy(() => import("./Vitae/pages/PilotSearch"));
const InvitationResponse = React.lazy(() => import("./Vitae/recruiter/invitations/InvitationResponse"));
const InvitationsList = React.lazy(() => import("./Vitae/recruiter/invitations/Invitations"));
const Applications = React.lazy(() => import("./Vitae/recruiter/Applications/Applications"));
const ApplicationDetails = React.lazy(() => import("./Vitae/pilot/ApplicationDetails/ApplicationDetails"));
const PilotApplications = React.lazy(() => import("./Vitae/pilot/PilotApplications/PilotView"));
const Attachments = React.lazy(() => import("./Vitae/recruiter/attachments/Attachments"));
const Welcome = React.lazy(() => import("./Vitae/pilot/Welcome/Welcome"));
const Submit = React.lazy(() => import("./Vitae/common/forms/Submit"));

// End: Vitae

// Routes that are accessible by all users
const unrestrictedRoutes = [
	{ path: "/", exact: true, name: "Home" },
	{ path: "/redeem/:config?/:code?", name: "Redeem", component: Redeem },
	{ path: "/redeemed", name: "Redeemed", component: Redeemed },
	{ path: "/already-redeemed", name: "Already Redeemed", component: AlreadyRedeemed },
	{ path: "/invitation/:id/accept", exact: true, name: "Accept Invitation", component: InvitationResponse },
	{ path: "/vitae/profile", exact: true, name: "Vitae Profile", component: PilotProfile },

	// TODO: VIT-893
	// { path: "/vitae/search", exact: true, name: "Vitae Pilot Search", component: PilotSearch },
	{ path: "/account", exact: true, name: "Account Settings", component: Account },
];

const supportRoutes = [
	{ path: "/jedi-basic-tools", exact: true, name: "Jedi Basic Tools", component: JediBasicTools },
	{ path: "/file-tools", exact: true, name: "File Tools", component: FileTools },
	{ path: "/persistent-links", exact: true, name: "Persistent Links", component: PersistentLinks },
	{ path: "/logbook-tools", exact: true, name: "Logbook Tools", component: LogbookTools },
];

const marketingRoutes = [
	{ path: "/coupons", exact: true, name: "Coupons", component: Coupons },
	{ path: "/coupons/:id", exact: true, name: "Coupon Detailed", component: CouponDetailed },
	{ path: "/coupons/:id/users", exact: true, name: "Coupon Users", component: CouponUsers },
];
const vitaePilotRoutes = [
	{ path: "/pilot-applications/", exact: true, name: "Welcome", component: Welcome },
	{ path: "/pilot-applications/submit", exact: true, name: "Review & Submit", component: Submit },
	{ path: "/pilot-applications/:id", name: "Pilot Applications", component: PilotApplications },
];
const vitaeRecruiterRoutes = [
	{ path: "/invitations", exact: true, name: "Invitations", component: InvitationsList },
	{ path: "/applications", exact: true, name: "Applications", component: Applications },
	{ path: "/application-details/:id", exact: true, name: "Application Details", component: ApplicationDetails },
	{ path: "/attachments", exact: true, name: "Attachments", component: Attachments },
];

function routesForRoles(roles) {
	const groups = roles.map(role => routeGroupsForRole(role));
	return groups;
}

function routeGroupsForRole(role) {
	switch (role) {
		case ROLES.ADMIN:
			return [...supportRoutes, ...marketingRoutes];

		case ROLES.VITAE.RECRUITER:
			return vitaeRecruiterRoutes;

		case ROLES.VITAE.PILOT:
			return vitaePilotRoutes;
		default:
			return [];
	}
}

export default roles => {
	if (roles && roles.length) {
		const routes = routesForRoles(roles);
		return flattenDeep([...unrestrictedRoutes, ...routes]);
	}
	return unrestrictedRoutes;
};

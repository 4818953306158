import React from "react";
import { Typography, Paper, PaperProps } from "@coradine/web-ui";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
	/** Styles passed to the Paper element */
	paper: {
		width: "100%",
		padding: theme.spacing(3),
		marginBottom: theme.spacing(3),
		borderRadius: 14,
	},

	/** Styles passed to the title of the Card */
	title: {
		fontWeight: theme.typography.fontWeightMedium,
		titleAlign: ({ titleAlign }: CardProps) => titleAlign || "left",
	},
}));
export interface CardProps extends PaperProps {

	/** Title of the card */
	title?: string;

	/**
	 * Allows title to be text
	 * @default "left"
	 */
	titleAlign?: React.CSSProperties["textAlign"];
}

/** Multi-purpose Card that keeps consistent spacing and possibility to show a main title */
export const Card: React.FC<CardProps> = ({ children, title, className, titleAlign = "left", ...restProps }: CardProps) => {
	const classes = useStyles({ titleAlign });
	return (
		<Paper elevation={0} className={clsx(classes.paper, className)} {...restProps}>
			{title && (
				<Typography variant="h4" className={classes.title}>
					{title}
				</Typography>
			)}
			{children}
		</Paper>
	);
};

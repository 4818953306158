const AUTH_REQUEST = "AUTH_REQUEST";
const SAVE_AUTH_TOKEN = "SAVE_AUTH_TOKEN";
const VITAE_AUTH_INDIVIDUAL = "VITAE_AUTH_INDIVIDUAL";
const VITAE_AUTH_ORGANIZATION = "VITAE_AUTH_ORGANIZATION";
const AUTH_ERROR = "AUTH_ERROR";
const RETRIEVE_MEMBERSHIPS_REQUEST = "RETRIEVE_MEMBERSHIPS_REQUEST";
const RETRIEVE_MEMBERSHIPS_SUCCESS = "RETRIEVE_MEMBERSHIPS_SUCCESS";
const RETRIEVE_MEMBERSHIPS_ERROR = "RETRIEVE_MEMBERSHIPS_ERROR";
const POPULATE_AUTH_INITIAL_STATE = "POPULATE_AUTH_INITIAL_STATE";

export default {
	AUTH_REQUEST,
	SAVE_AUTH_TOKEN,
	VITAE_AUTH_INDIVIDUAL,
	VITAE_AUTH_ORGANIZATION,
	AUTH_ERROR,
	RETRIEVE_MEMBERSHIPS_REQUEST,
	RETRIEVE_MEMBERSHIPS_SUCCESS,
	RETRIEVE_MEMBERSHIPS_ERROR,
	POPULATE_AUTH_INITIAL_STATE,
};

import React from "react";
import { LayoutFooter, Pill } from "@coradine/web-ui";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import packageJson from "../../../package.json";
import { ReduxStore } from "../../store/types";

const DefaultFooter = () => {
	const isAdmin: boolean = useSelector((state: ReduxStore) => state.user.userAdmin);
		
	const userCanSeeBuildInfo
			= isAdmin
			|| process.env.REACT_APP_CORADINE_ENV === "dev_local"
			|| process.env.REACT_APP_CORADINE_ENV === "dev"
			|| process.env.REACT_APP_CORADINE_ENV === "test";

	const version = `${process.env.REACT_APP_CORADINE_ENV} v${packageJson.version} (${process.env.REACT_APP_GIT_REV_LIST})`;

	const links = [{ children: "Privacy Policy", href: process.env.REACT_APP_CORADINE_PRIVACY_POLICY_URL, target: "_blank" }];
		
	return (
		<Box textAlign="center" data-cy="footer">
			{userCanSeeBuildInfo && <Pill color="secondary" label={version}/>}
			<LayoutFooter links={links} />
		</Box>
	);
};

export default DefaultFooter;

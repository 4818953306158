import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { brandHtmlTemplate } from "../store/model/brand";
import { ReduxStore } from "../store/types";

interface BrandFragmentProps {
	page: string;
	section: string;
}

const useBrandState = (props: BrandFragmentProps) => {
	const { loadingManifest, loadingContent, content } = useSelector((state: ReduxStore) => state.brand);
	const { page, section } = props;

	const pageContent = content[page] || {};

	// Determine if the target fragment is being loaded.
	const pageLoading = loadingContent[page] || {};
	const sectionLoading = pageLoading[section] || false;

	return {
		loading: loadingManifest || sectionLoading,
		content: pageContent[section],
	};
};

const BrandFragment: React.FC<BrandFragmentProps> = props => {
	const { page, section } = props;
	const { loading, content } = useBrandState(props);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!loading && !content) {
			dispatch(brandHtmlTemplate(page, section));
		}
	}, [dispatch, loading, content, page, section]);

	return !loading && content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : null;
};

export default BrandFragment;

import moment from "moment";

const defaultFormat = "DD MMM YYYY";

/**
 * Converts date into a string
 * @param date date to be formatted
 * @returns formatted date string
 */
export const formatDate = (date?: Date | string, format = defaultFormat): string | null => {
	const newDate = moment(date);
	if (date && newDate.isValid()) {
		return newDate.format(format);
	}
	return null;
};

export const datePlaceholder = (format = defaultFormat): string => {
	const placeholder = format.replace(/[A-Z]|[a-z]+(?!$)/gu, "-").toLowerCase();
	return placeholder;
};

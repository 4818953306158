import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { useSelector } from "react-redux";
import { axios } from "./api/utils/axios";

// `class-transformer` packages relies on this `reflect-metadata` import.
// see: https://github.com/typestack/class-transformer#nodejs
import "reflect-metadata";

import { loadProgressBar } from "axios-progress-bar";

// Shows a loading bar on top of the screen, when any axios request is in progress
loadProgressBar("", axios);

// Styles
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";

// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";

// Import Main styles for this application
import "./scss/style.css";

// Main Styles for React Grid Module (Boostrap 4)
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";

// React Ladda style
import "ladda/dist/ladda-themeless.min.css";

// Containers
import { DefaultLayout } from "./containers";

// Pages
import BrandFetch from "./common/BrandFetch";

// Axios progress bar
import "axios-progress-bar/dist/nprogress.css";

import { ReduxStore } from "./store/types";
import { useGetTou } from "./hooks/useGetTou";
import { AuthType } from "./views/Pages/Auth";

const Redeem = React.lazy(() => import("./WebAdminApp/Redeem/Redeem"));
const Auth = React.lazy(() => import("./views/Pages/Auth"));
const Logout = React.lazy(() => import("./views/Pages/Logout"));
const SSO = React.lazy(() => import("./views/Pages/SSO"));
const InvitationResponse = React.lazy(() => import("./Vitae/recruiter/invitations/InvitationResponse"));
const Page404 = React.lazy(() => import("./views/Pages/Page404/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500/Page500"));
const IntegrateService = React.lazy(() => import("./Vitae/pages/IntegrateService"));

const loading = () => <div className="pt-3 text-center animated fadeIn">Loading...</div>;

export type LocationState = {
	original: RouteComponentProps["location"];
};

export type RouteProps = RouteComponentProps<Record<string, any>, StaticContext, LocationState>;

/** @return A Route that redirects to /login if a user is not logged in or hasn't accepted latest ToU. */
const RestrictedRoute = ({ component: Component, loggedIn, redirectTo = "/signup", ...rest }) => {
	const { isLoading, touAccepted } = useGetTou({ enabled: loggedIn });

	return isLoading ? loading() : (
		<Route
			{...rest}
			render={props => {
				if (loggedIn && touAccepted) {
					return <Component {...props} />;
				}
				const state: LocationState = { original: props.location };
				return <Redirect to={{ pathname: redirectTo, state }} />;
			}}
		/>
	);
};

const App = () => {
	const loggedIn = useSelector((state: ReduxStore) => Boolean(state.user.sessionToken));
	return (
		<Router>
			<div>
				<BrandFetch />
				<React.Suspense fallback={loading()}>
					<Switch>
						<RestrictedRoute loggedIn={loggedIn} path="/redeem/:config?/:code?" component={Redeem} />
						<RestrictedRoute loggedIn={loggedIn} redirectTo="/login" path="/integrate" component={IntegrateService} />
						<Route
							exact
							path="/login"
							component={(props: RouteProps) => <Auth {...props} type={AuthType.LOGIN} />}
						/>
						<Route
							exact
							path="/signup"
							component={(props: RouteProps) => <Auth {...props} type={AuthType.SIGNUP} />}
						/>
						<Route exact path="/logout" component={Logout} />
						<Route path="/sso" component={SSO} />
						<Route
							path="/invitation/:id/decline"
							component={props => <InvitationResponse {...props} type="decline" />}
						/>
						<Route exact path="/404" component={Page404} />
						<Route exact path="/500" component={Page500} />
						<Route path="/" component={DefaultLayout} />
					</Switch>
				</React.Suspense>
			</div>
		</Router>
	);
};

export default App;

import { createReducer } from "@reduxjs/toolkit";
import types from "./actionTypes";
import _ from "lodash";

export const initialState = {
	invitations: [],
	invitationsFiltered: [],
	isFilterActive: false,
};

const invitationReducer = createReducer(initialState, {
	[`${types.FETCH_INVITATIONS}_FULFILLED`]: (state, action) => {
		const invitations = action.payload.data;
		return { ...state, invitations };
	},
	[`${types.CREATE_INVITATION}_FULFILLED`]: (state, action) => {
		const invitationToCreate = action.payload.data;
		state.invitations.push(invitationToCreate);
	},
	[`${types.REMOVE_INVITATION}_FULFILLED`]: (state, action) => {
		const invitationToRemove = _.get(action, "meta.id");
		return { ...state, invitations: state.invitations.filter(invitation => invitation.id !== invitationToRemove) };
	},
	[types.FILTER_INVITATIONS]: (state, action) => {
		const filteredInvitations = action.filteredData;
		return { ...state, invitationsFiltered: filteredInvitations, isFilterActive: true };
	},
	[types.CLEAR_FILTER]: (state, action) => {
		const filteredInvitations = [];
		return { ...state, invitationsFiltered: filteredInvitations, isFilterActive: false };
	},
});

export default invitationReducer;

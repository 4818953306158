import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";

import types from "./actionTypes";
import { applyPatch } from "rfc6902";

export const initialState = {
	attachments: {},
	table: null,
	dynamicKey: null,
	pilotApplicationIds: {},
	pilotDetails: null,
	formStructure: [],
	pendingPatchHistory: [], // PATCH instructions that are being on the way to the server
	workingPatchHistory: [], // Local PATCH instructions
};

const dynamicFormReducer = createReducer(initialState, {
	[`${types.FETCH_APPLICATION_DATA}_FULFILLED`]: (state, action) => {
		const { data, documentApprovalStates } = action.payload.data.attributes;
		const combinedPatchHistory = [...state.pendingPatchHistory, ...state.workingPatchHistory];

		// Update pilotData with both pending & working PATCH instructions
		applyPatch(action.payload.data.attributes, combinedPatchHistory);

		return {
			...state,
			pilotDetails: data,
			documentApprovalStates: documentApprovalStates,
		};
	},
	[`${types.FETCH_APPLICATION_TEMPLATE}_FULFILLED`]: (state, action) => {
		return { ...state, formStructure: action.payload.data.attributes.form };
	},
	[`${types.FETCH_FILE}_FULFILLED`]: (state, action) => {
		const { payload: { keys, blob } } = action;
		state.attachments = _.set(state.attachments, keys, URL.createObjectURL(blob));
	},
	[types.SAVE_FILE_BLOB]: (state, action) => {
		const { keys, blob } = action;
		state.attachments = _.set(state.attachments, keys, blob);
	},
	[types.SET_TABLE_DATA]: (state, action) => {
		const table = action.table;
		const dynamicKey = action.dynamicKey;
		return { ...state, table, dynamicKey };
	},
	[types.PATCH_LOCAL_APPLICATION_DATA]: (state, action) => ({
		...state,
		pilotDetails: action.patchedApplicationData,
	}),
	[types.SET_PATCH_HISTORY]: (state, action) => {
		return { ...state, workingPatchHistory: [...state.workingPatchHistory, ...action.patchHistory] };
	},
	[types.UPDATE_PATCH_HISTORY]: (state) => {
		return { ...state, pendingPatchHistory: state.workingPatchHistory, workingPatchHistory: [] };
	},
	[types.RESET_PATCH_HISTORY]: (state) => {
		return { ...state, pendingPatchHistory: [] };
	},
	[types.ROLLBACK_PATCH_HISTORY]: (state) => {
		return { ...state, workingPatchHistory: [...state.pendingPatchHistory, ...state.workingPatchHistory], pendingPatchHistory: [] };
	},
	[types.SET_APPLICATION_IDS]: (state, action) => {
		const pilotApplicationIds = action.applicationIds;
		return { ...state, pilotApplicationIds };
	},
	"USER_LOGOUT": () => {
		return initialState;
	},
});

export default dynamicFormReducer;

import React from "react";
import { CoradineLogo, LayoutContent, LayoutHeader } from "@coradine/web-ui";

import DefaultFooter from "../DefaultLayout/DefaultFooter";

type GlobalLayoutProps = {
	hideTopBar?: boolean;
};

/** Serves as a layout wrapper/container for global pages that do not require authentication (ie: Auth, Logout ...) */
export const GlobalLayout: React.FC<GlobalLayoutProps> = ({ children, hideTopBar }) => (
	<div>
		{!hideTopBar && (
			<>
				{/* No sidebar required when user is logged out */}
				{/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
				<LayoutHeader hideSidebarToggle isShifted={false} toggleSidebar={() => {}}>
					<CoradineLogo type="full" variant="normal" style={{ width: "100%" }} />
				</LayoutHeader>
			</>
		)}
		<LayoutContent footer={<DefaultFooter />}>{children}</LayoutContent>
	</div>
);

/** Wraps any component with a GlobalLayout */
export function withGlobalLayout<P>(WrappedComponent: React.ComponentType<P>, layoutProps?: GlobalLayoutProps) {
	const ComponentWithGlobalLayout = (props: P) => {
		return (
			<GlobalLayout {...layoutProps}>
				<WrappedComponent {...props} />
			</GlobalLayout>
		);
	};
	return ComponentWithGlobalLayout;
}

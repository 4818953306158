import React from "react";
import { Typography } from "@coradine/web-ui";
import { TypographyProps } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import _get from "lodash/get";

export interface TProps extends Omit<TypographyProps, "color"> {

	/** Theme palette color key (ie: `primary.main` which is equal to `theme.palette.primary.main`) */
	color?: string;

	/** HTML component that the typography element should use (ie: span) */
	component?: any;

	/** Font Weight of the typography element */
	fontWeight?: "fontWeightLight" | "fontWeightRegular" | "fontWeightMedium" | "fontWeightBold";

	/** Margin bottom applied that should be applied to the typography element */
	mb?: React.CSSProperties["marginBottom"];
}

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
	root: {
		fontWeight: ({ fontWeight }: TProps) => (fontWeight ? typography[fontWeight] : typography.fontWeightRegular),
		marginBottom: ({ mb }: TProps) => mb,
		color: ({ color }: TProps) => _get(palette, color || "text.primary"),
	},
}));

const GenericTypography = (props: TProps) => {
	const { className, variant, color = "text.primary", ...restProps } = props;
	const classes = useStyles({ color, ...restProps });

	return <Typography className={clsx(classes.root, className)} variant={variant} {...restProps} />;
};

export const H1 = (props: TProps) => <GenericTypography variant="h1" {...props} />;
export const H2 = (props: TProps) => <GenericTypography variant="h2" {...props} />;
export const H3 = (props: TProps) => <GenericTypography variant="h3" {...props} />;
export const H4 = (props: TProps) => <GenericTypography variant="h4" {...props} />;
export const H5 = (props: TProps) => <GenericTypography variant="h5" {...props} />;
export const H6 = (props: TProps) => <GenericTypography variant="h6" {...props} />;
export const Text = (props: TProps) => <GenericTypography variant="h5" component="span" mb={0} {...props} />;
export const Small = (props: TProps) => <GenericTypography variant="body2" component="span" {...props} />;

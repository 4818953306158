import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import types from "./actionTypes";
import { vitaeAuthTokenKey, vitaeIndividualTokenKey, vitaeOrganizationTokenKey } from "../../../utils/localStorage";

const clearLocalStorage = () => {
	localStorage.removeItem(vitaeAuthTokenKey);
	localStorage.removeItem(vitaeIndividualTokenKey);
	localStorage.removeItem(vitaeOrganizationTokenKey);
};

export const initialState = {
	memberships: {},
	authToken: undefined, // Represents the current token (individual or organization) used by the user
	individualToken: undefined, // Authorization token used as an individual (ie: pilot)
	organizationToken: undefined, // Authorization token used as a member of an organization (ie: recruiter)
	authProcessing: false,
	authError: undefined,
	membershipsProcessing: false,
	membershipsError: undefined,
	activeMembership: null,
};

const authReducer = createReducer(initialState, {
	// Auth
	[types.AUTH_REQUEST]: (state) => {
		return { ...state, authProcessing: true };
	},
	[types.SAVE_AUTH_TOKEN]: (state, action) => {
		localStorage.setItem(vitaeAuthTokenKey, action.token);
		return { ...state, authToken: action.token, authProcessing: false };
	},
	[types.VITAE_AUTH_INDIVIDUAL]: (state, action) => {
		localStorage.setItem(vitaeIndividualTokenKey, action.token);
		localStorage.setItem(vitaeAuthTokenKey, action.token);
		return { ...state, activeMembership: null, authToken: action.token, individualToken: action.token, authProcessing: false };
	},
	[types.VITAE_AUTH_ORGANIZATION]: (state, action) => {
		localStorage.setItem(vitaeOrganizationTokenKey, action.token);
		localStorage.setItem(vitaeAuthTokenKey, action.token);
		return { ...state, activeMembership: action.membershipId, authToken: action.token, organizationToken: action.token, authProcessing: false };
	},
	[types.AUTH_ERROR]: (state, action) => {
		return { ...state, authError: action.error, authProcessing: false };
	},
	
	// Memberships
	[types.RETRIEVE_MEMBERSHIPS_REQUEST]: (state) => {
		return { ...state, membershipsProcessing: true };
	},
	[types.RETRIEVE_MEMBERSHIPS_SUCCESS]: (state, action) => {
		return { ...state, memberships: _.mapKeys(action.memberships, "id"), membershipsProcessing: false };
	},
	[types.RETRIEVE_MEMBERSHIPS_ERROR]: (state, action) => {
		return { ...state, membershipsError: action.error, membershipsProcessing: false };
	},

	// Initial State - populate stored tokens from localStorage
	[types.POPULATE_AUTH_INITIAL_STATE]: (state) => {
		const authToken = localStorage.getItem(vitaeAuthTokenKey);
		const individualToken = localStorage.getItem(vitaeIndividualTokenKey);
		const organizationToken = localStorage.getItem(vitaeOrganizationTokenKey);
		return { ...state, authToken, individualToken, organizationToken };
	},

	// User logout, clear tokens
	"USER_LOGOUT": () => {
		clearLocalStorage();
		return initialState;
	},
});

export default authReducer;

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import { Box, IconButton, InputAdornment } from "@material-ui/core";
import {
	CoradineLogo,
	Link as CASLink,
	LoadingButton,
	TextField,
	TextFieldProps,
	toast, Typography,
} from "@coradine/web-ui";
import { Link } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import { Card } from "../../../common/Card";
import { BrandedContent } from "../../../containers/ContentLayout/BrandedContent";
import { EyeOpenIcon, EyeClosedIcon } from "../../../assets/icons";
import { ReduxStore } from "../../../store/types";
import {
	login,
	signup,
	resetPassword,
	logout,
	clearAuthMessages,
} from "../../../store/model/user";
import { validateEmail, validatePassword } from "../../../utils/helpers";
import { LocationState, RouteProps } from "../../../App";
import { withGlobalLayout } from "../../../containers/GlobalLayout";
import { ToUModal } from "./ToUModal";
import { useGetTou } from "../../../hooks/useGetTou";
import { useAcceptTou } from "../../../hooks/useAcceptTou";
import { queryClient } from "../../../utils/reactQueryClient";
import { TOU } from "../../../hooks/constants";
import { Tou, TouUserAcceptance } from "../../../api/models/tou";

export enum AuthType {
	LOGIN = "login",
	SIGNUP = "signup",
}

export interface AuthProps extends RouteProps {
	type: AuthType;
}

type AuthLocationState = RouteProps["location"] & LocationState & {
	state: {
		defaultValues: FormInput;
	};
};

export interface FormInput {
	email: string;
	password: string;
	verifyPassword: string;
}

type FormElements = {
	[k in keyof FormInput]: TextFieldProps & { "data-cy"?: string; "data-testid"?: string };
};

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		paddingTop: 32,
		paddingBottom: 32,
	},
	icon: {
		color: theme.palette.primary.main,
	},
	adornment: {
		position: "absolute",
		right: 0,
	},
	passwordInput: {
		paddingRight: 46,
	},
	submitButton: {
		minWidth: 113,
		"&:focus": {
			outline: 0,
			boxShadow: "0 0 0 0.2rem rgba(23, 132, 255, 0.25) !important",
		},
	},
}));

interface PasswordFieldProps extends TextFieldProps {
	withPasswordReset?: boolean;
	handlePasswordReset?: () => void;
}

const PasswordField = ({
	withPasswordReset = false,
	handlePasswordReset,
	...props
}: PasswordFieldProps) => {
	const [showPassword, setShowPassword] = useState(false);

	const { passwordInput, adornment, icon } = useStyles(props);

	return <TextField
		inputProps={{ className: passwordInput }}
		type={showPassword ? "text" : "password"}
		InputProps={{
			endAdornment: (
				<InputAdornment position="end" className={adornment}>
					<IconButton
						tabIndex={-1}
						aria-label="toggle password visibility"
						onClick={() => setShowPassword(!showPassword)}
						onMouseDown={(event) => event.preventDefault()}
						className={icon}
					>
						{showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
					</IconButton>
				</InputAdornment>
			),
		}}
		label={
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<span>Password</span>
				{withPasswordReset && (
					<CASLink onClick={handlePasswordReset} data-cy="forgot-password" tabIndex={-1}>
						Forgot Password?
					</CASLink>
				)}
			</Box>
		}
		{...props}
	/>;
};

const formElements: FormElements = {
	email: {
		name: "email",
		id: "email",
		label: "Email",
		type: "email",
		"data-cy": "email-input",
	},
	password: {
		name: "password",
		id: "password",
		"data-cy": "password-input",
	},
	verifyPassword: {
		name: "verifyPassword",
		id: "verifyPassword",
		label: "Re-type Password",
		"data-testid": "verify-password-input",
		"data-cy": "verify-password-input",
	},
};

export const Auth = (props: AuthProps) => {
	// store input fields we want to share between LOGIN and SIGNUP forms
	const location = props.location as AuthLocationState;
	const [defaultValues, setDefaultValues] = useState(location.state?.defaultValues);

	const [type, setType] = useState<AuthType>(props.type);
	const [showTerms, setShowTerms] = useState(false);
	const classes = useStyles();
	const {
		error,
		resetError,
		resetMessage,
		sessionToken,
		processing,
	} = useSelector((reduxState: ReduxStore) => reduxState.user);
	const dispatch = useDispatch();

	const {
		isLoading: isLoadingTou,
		data,
		touAccepted,
		error: touError,
	} = useGetTou({ enabled: Boolean(sessionToken) });
	const { isLoading: isAcceptingTou, mutate } = useAcceptTou();

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setError,
		setValue,
	} = useForm<FormInput>({
		mode: "onChange",
		defaultValues,
	});

	const isLogin = type === AuthType.LOGIN;
	const isSignup = type === AuthType.SIGNUP;

	const onSubmit: SubmitHandler<FormInput> = ({ email, password }: FormInput) => {
		if (isLogin) {
			return dispatch(login(email, password));
		}
		return dispatch(signup(email, password));
	};

	const handleAuthTypeChange = () => {
		const newType = isLogin ? AuthType.SIGNUP : AuthType.LOGIN;
		setType(newType);
	};

	const handlePasswordReset = () => {
		const { email } = getValues();
		if (validateEmail(email)) {
			dispatch(resetPassword(email));
		}
		else {
			setError("email", {
				message: "Please enter a valid email address to reset your password.",
			});
		}
	};

	const handleAccept = () => mutate(undefined, {
		onSuccess: () => {
			queryClient.setQueryData<Tou>(TOU, (oldData) => ({
				...oldData,
				user_acceptance: TouUserAcceptance.Valid,
			}));
		},
		onError: () => {
			toast.error("Something went wrong while accepting the Terms of Use.");
		},
	});

	const handleDecline = () => {
		setShowTerms(false);
		dispatch(logout());
	};

	const handleRedirect = () => {
		// Navigate to the path we were redirected from, or the root.
		const redirectUrl = props.location?.state?.original;
		const newPath = redirectUrl ? redirectUrl.pathname : "/account";
		const search = redirectUrl && redirectUrl.search;
		return <Redirect to={{ pathname: newPath, search }} />;
	};

	useEffect(() => {
		if (resetMessage) {
			toast.success(resetMessage);
			dispatch(clearAuthMessages());
		}
		if (error || resetError) {
			toast.error(error || resetError);
			dispatch(clearAuthMessages());
		}
		else if (touError?.message) {
			toast.error("Terms of use declined, signing out.");
		}
	}, [resetMessage, error, resetError, touError?.message]);

	useEffect(() => {
		if (sessionToken) {
			setShowTerms(!touAccepted);
		}
	}, [sessionToken, touAccepted]);

	if (sessionToken && touAccepted) {
		return handleRedirect();
	}

	const sanitizeEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { value } = event.target;
		const sanitized = value.toLowerCase().replace(/[^\w#!$%&'*/=?^_{}|~@.+-]/gu, "");

		setValue("email", sanitized);
		return event;
	};

	const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		sanitizeEmail(event);
		setDefaultValues({
			email: getValues("email"),
			password: "",
			verifyPassword: "",
		});
	};

	const determineStatus = (field: keyof FormInput): Pick<TextFieldProps, "state" | "helperText"> => {
		const hasValue = isSignup && Boolean(getValues()[field]);
		const hasError = errors[field];
		let state: TextFieldProps["state"] = "default";
		let helperText = "";

		if (hasError) {
			state = "error";
			helperText = errors[field]?.message || "";
			return { state, helperText };
		}
		else if (hasValue) {
			state = "success";
		}

		switch (field) {
			case formElements.email.name:
				helperText = hasValue ? "Email OK" : "";
				break;
			case formElements.password.name:
				helperText = hasValue ? "Password OK" : "Must contain 10 characters";
				break;
			case formElements.verifyPassword.name:
				helperText = hasValue ? "Password OK" : "Must match password";
				break;
			default:
				break;
		}
		return { state, helperText };
	};

	return (
		<BrandedContent page={type} section="header">
			<Box width="100%" maxWidth="420px">
				<Card
					className={classes.card}
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Box display="flex" flexDirection="column" gridRowGap="18px" alignItems="center">
							<CoradineLogo type="full" variant="normal" style={{ width: 306, height: 40 }} />

							<Typography variant="h4">
								{`${isLogin ? "Login to your" : "Signup for a new"} Coradine Account`}
							</Typography>
						</Box>

						<Box display="flex" flexDirection="column" style={{ gap: 18 }}>
							<TextField
								inputRef={register({
									required: "Email is required.",
									validate: value => validateEmail(value) || "Invalid email address.",
								})}
								{...formElements.email}
								{...determineStatus("email")}
								onChange={onChangeEmail}
							/>
							<PasswordField
								inputRef={register({
									required: "Password is required.",
									validate: (value) => {
										// only validate password length on signup, allow existing creds with short passwords (JIRA: WEB-709)
										return isSignup
											? validatePassword(value) || "Passwords must be at least 10 characters."
											: true;
									},
								})}
								withPasswordReset={isLogin}
								handlePasswordReset={handlePasswordReset}
								{...formElements.password}
								{...determineStatus("password")}
							/>
							{isSignup && <PasswordField
								inputRef={register({
									required: "Verify password is required.",
									validate: (value) => value === getValues().password || "Passwords must match.",
								})}
								{...formElements.verifyPassword}
								{...determineStatus("verifyPassword")}
							/>}
							<Box display="flex" justifyContent="flex-end">
								<LoadingButton
									variant="contained"
									type="submit"
									color="primary"
									data-cy="auth-submit"
									data-testid="auth-submit"
									className={classes.submitButton}
									loading={processing || isLoadingTou}
								>
									{isLogin ? "Login" : "Signup"}
								</LoadingButton>
							</Box>
						</Box>
					</form>
					<Box mt={3} textAlign="center">
						<Link
							to={{
								...location,
								pathname: isLogin ? "/signup" : "/login",
								state: { ...location.state, defaultValues },
							}}
							onClick={handleAuthTypeChange}
							data-cy={isLogin ? "signup-link" : "login-link"}
						>
							{isLogin ? "Don’t have an account yet? Sign up." : "Already have an account? Log in."}
						</Link>
					</Box>
				</Card>
				{data?.content && data?.publish_date && <ToUModal
					handleAccept={handleAccept}
					handleDecline={handleDecline}
					open={showTerms}
					content={data?.content}
					updatedAt={data?.publish_date}
					isAcceptingTou={isAcceptingTou}
				/>}
			</Box>
		</BrandedContent>
	);
};

export const WrappedAuth = withGlobalLayout(Auth, { hideTopBar: true });
